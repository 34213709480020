/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      mname
      lname
      bdate
      contact
      role
      date
      gender
      status
      district
      username
      createdAt
      department
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mname
        lname
        bdate
        contact
        role
        date
        gender
        status
        district
        username
        createdAt
        department
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userbyUsername = /* GraphQL */ `
  query UserbyUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        bdate
        contact
        role
        date
        gender
        status
        district
        username
        createdAt
        department
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
