import { useState } from "react";

import { Button, notification, Modal, Row, Col } from "antd";
import {
  DeleteOutlined
} from "@ant-design/icons";

import { API, graphqlOperation } from "aws-amplify";
import { deleteUser } from "./../../api/mutations.js";

let AWS = require("aws-sdk");
let cognitoISP = new AWS.CognitoIdentityServiceProvider({
  region: "ap-southeast-1",
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default function DeleteUser({data, refresh}){
  const [openModal, setOpenModal] = useState(false)
  const handleDelete = async() => {
  	try{
      const deleteId = await API.graphql(graphqlOperation(deleteUser, {input: {id: data.id} }) )

      if(deleteId.data.deleteUser){
        let awsDeleteUser = new Promise((resolve, reject) => {
          let params = {
            UserPoolId: "ap-southeast-1_8bRtLOuCc",
            Username: data.username,
          };

          cognitoISP.adminDeleteUser(params, (err, data) =>
            err ? reject(err) : resolve("Success")
          );
        });

        awsDeleteUser
          .then(res => {
            notification["success"]({
              message: "Success",
              description: "User is deleted.",
            });

            setTimeout(() => {
              handleCloseModal();
              refresh();
            }, 3000)
          })
          .catch(error => {
            notification["error"]({
              message: "Error",
              description: error.stack,
            });

            console.log(error);
          });
      }else {
        notification["error"]({
          message: "Error",
          description: "Contact System Admin.",
        });
      }
  	}catch(err){
  		console.log(err)
  	}
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return(
  	<>
  		<Button 
        onClick={handleOpenModal} 
        size="small" 
        type="danger"
        style={{width:"56px" , borderRadius:"20px"}} 
      >
        <DeleteOutlined />
      </Button>

      <Modal
        title={`Delete user: ${data.name} ${data.mname? data.mname : ""} ${data.lname}`} 
        onCancel={handleCloseModal}
        visible={openModal}
        footer={null}
        width={300}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <Button onClick={handleCloseModal} type="primary">Cancel</Button>
              <Button type="danger" onClick={handleDelete} >Delete</Button>
            </Row>
          </Col>
        </Row>
      </Modal>
  	</>
  )
}