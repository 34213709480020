/**
 * This component is used to sign out the user when the token is expired and redirect to the login page.
 * It uses the Auth.signOut() method from AWS Amplify to sign out the user and clear the local storage.
 * It also uses the setTimeout() method to redirect the user to the login page after 3 seconds.
 * The component is rendered when the token is expired and the user is redirected to the login page.
 * 
 * @file This file defines the TokenExpired component.
 * @author Honoratt Mark B Ocbina.
 */


import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

// This component is used to handle token expiration
function TokenExpired() {
  // State for loading status
  const [loading, setLoading] = useState(true);

  // Effect hook to handle sign out when token is expired
  useEffect(() => {
    // Function to sign out the user
    async function signOut() {
      try {
        // Sign out globally using AWS Amplify Auth
        await Auth.signOut({ global: true });
      } catch (error) {
        // Log the error if it's not a 'NotAuthorizedException'
        if (error.code !== 'NotAuthorizedException') {
          console.error('error signing out: ', error);
          return;
        }
      }

      // Set a timeout to clear local storage and redirect to login page after 3 seconds
      setTimeout(() => {
        setLoading(false);
        localStorage.clear();
        window.history.replaceState({}, document.title, window.location.pathname);
        window.location.href ="/";
      }, 3000);
    }

    // Call the signOut function
    signOut();
  }, []);

  // If loading, display a loading message
  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        animation: 'fade 2s infinite'
      }}>
        Logging out...
      </div>
    );
  }

  // If not loading, don't render anything
  return null;
}

export default TokenExpired;