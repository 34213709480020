import React, { useState, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    useEffect(() => {
      checkAuthState();
    }, []);
  
    async function checkAuthState() {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const localUser = localStorage.getItem('user');

          if (user && localUser) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (e) {
          setIsAuthenticated(false);
        }
    }
  
    return (
      <AuthContext.Provider value={{ isAuthenticated }}>
        {children}
      </AuthContext.Provider>
    );
  };

  
function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined)
      throw new Error("AuthContext was used outside AuthProvider");
    return context;
}

export { AuthProvider, useAuth };
  