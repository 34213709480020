import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';

const NotFound = () => {
    let history = useHistory();

    function handleClick() {
        history.push("/");
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
             <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
             />
            <button type="button" onClick={handleClick} 
            style={{
                border:"none", 
                padding:"10px", 
                width:"150px", 
                borderRadius:"20px",
                backgroundColor:"#1890ff",
                color:"#fff",
                fontWeight:"bolder",
                cursor:"pointer"
                }}>
                Go home
            </button>
        </div>
  );
};

export default NotFound;