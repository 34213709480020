import httpTechevalbe from "./axios/http-techevalbe";

class ApiTechevalBe {
   async checkPaymentIfExistforDBO(data) {
      try {
         const response = await httpTechevalbe.post("/check-if-paid-dbo", data);

         if(response.data["DBO"] === undefined ) return;

         if(response.data["DBO"].length === 0) return 0;

         return response.data;

      } catch (error) {
         console.error("Error checking payment:", error);
         throw error;
      }
   }
}

export default new ApiTechevalBe();