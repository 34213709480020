import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_RECAPTCHA_KEY } = process.env;

// window.grecaptcha.reset();
export default function RecaptchaRegister({setCaptcha, showButton}){
  const [show, setShow] = useState(false);

  const grecaptchaObject = window.grecaptcha;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      showButton(true);
    }, 1500)
  }, [])

  const handleRecaptcha = async(value) => {
    setCaptcha(value)
  }

  return(
    <>
      {
        show? (
          <>
            <ReCAPTCHA
              sitekey={REACT_APP_RECAPTCHA_KEY}
              onChange={handleRecaptcha}
              grecaptcha={grecaptchaObject}
            />
          </>
        ) : (null)
      }
          
    </>
  )
};