import { Descriptions, Drawer, Row, Col, Button, Modal, Input, notification } from "antd";
import {
  LockTwoTone,
} from "@ant-design/icons";

import React from "react";
import moment from "moment";

import { Auth } from 'aws-amplify';

export default class ViewProfile extends React.Component {
  constructor() {
    super();
    this.state = { 
      open: false,
      isModalVisible: false,
      password: ""
    };
  }

  //   componentDidMount(){
  //     this.setState({date: moment("20120620", "YYYYMMDD").fromNow()})
  //   }

  handleChangePassword = () => {
    this.setState({isModalVisible: true});
  }
  handleOk = () => {
    if(this.state.newPassword && this.state.confirmPassword && this.state.oldPassword){
      if(this.state.newPassword.length >= 8 && this.state.confirmPassword.length >= 8){
        if(this.state.confirmPassword === this.state.newPassword){

          Auth.currentAuthenticatedUser()
            .then(user => {
              return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
            })
            .then(data => {
              console.log(data);

              if(data == "SUCCESS"){
                setTimeout(() => {
                  this.setState({isModalVisible: false})
                }, 1000)
                notification["success"]({
                  message: "Success",
                  description:
                    "You've successfully changed your password.",
                });
              }else {
                alert("data");
              }
            })
            .catch(err => {
              notification["error"]({
                message: "Error",
                description:
                  "Please contact Administrator for further instructions.",
              });

              setTimeout(() => {
                console.log(err);
                this.setState({isModalVisible: false});
                this.setState({
                  confirmPassword: "",
                  oldPassword: "",
                  newPassword: "",
                });
              }, 1000)
            });          
        }else {
          notification["error"]({
            message: "Error",
            description:
              "Please make sure your new password matches.",
          });
        }
      }else {
        notification["error"]({
          message: "Error",
          description:
            "Please make sure your new password has 8 or more characters.",
        });
      }
    }else {
      notification["error"]({
        message: "Error",
        description:
          "Please make sure your all fields are filled-out.",
      });
    }
  }

  handleCancel = () => {
    this.setState({isModalVisible: false});
    this.setState({
      confirmPassword: "",
      oldPassword: "",
      newPassword: "",
    });
  }

  render() {
    const info = JSON.parse(localStorage.getItem("user"));
    let roles = info.attributes["custom:role"].split("|"); // adrian050724 updates
    let departments = info.attributes["custom:department"].split("|"); // adrian050724 updates
    // console.log(info);
    return (
      <>
        <div
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Profile
        </div>
        <Drawer
          width={500}
          //   title="Profile information"
          placement="left"
          onClose={() => this.setState({ open: false })}
          visible={this.state.open}
        >
          <Row>
            <Col>
              {info ? (
                <Descriptions
                  //   bordered
                  title="Profile Information"
                  layout="vertical"
                >
                  <Descriptions.Item label="Name" span={3}>
                    <b>{info.attributes.name}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Birthdate">
                    <b>{info.attributes.birthdate}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Contact" span={2}>
                    <b>{info.attributes.phone_number}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Username" span={3}>
                    <b>{info.attributes.email}</b>
                  </Descriptions.Item>

                   {/* start adrian050724 */}
                  <Descriptions.Item label="Role" span={3}>
                   <div>
                    {roles.map((role, index) => (
                      <p key={index}>{index+1}. {role}</p>
                    ))}
                   </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Department" span={3}>
                   <div>
                    {departments.map((department, index) => (
                      <p key={index}>{index+1}. {department}</p>
                    ))}
                   </div>
                  </Descriptions.Item>
                   {/* end adrian050724 */}

                  <Descriptions.Item label="Member since" span={3}>
                    <b>
                      {moment(new Date(info.attributes["custom:date"])).fromNow()}
                    </b>
                  </Descriptions.Item>
                </Descriptions>
              ) : null}
            </Col>
            <Col flex className="changepwdiv">
              <Row align="bottom">
                <Button 
                  style={{fontSize: '1.25rem'}} 
                  onClick={this.handleChangePassword} 
                  value="large" 
                  type="link"
                >
                  Change Password
                  </Button>
              </Row>
            </Col>
          </Row>
        </Drawer>
        <Modal 
          title="Basic Modal" 
          visible={this.state.isModalVisible} 
          onOk={this.handleOk} 
          onCancel={this.handleCancel}
        >
          <Input.Password
            value={this.state.oldPassword}
            prefix={<LockTwoTone twoToneColor="#DF3535" />}
            placeholder=" Your old password"
            onChange={(e) => this.setState({ oldPassword: e.target.value })}
            size="large"
            required
            className="loginInput"
            style={{marginBottom: '1rem'}}
          />
          <Input.Password
            value={this.state.newPassword}
            prefix={<LockTwoTone twoToneColor="#DF3535" />}
            placeholder=" Your new password"
            onChange={(e) => this.setState({ newPassword: e.target.value })}
            size="large"
            required
            className="loginInput"
            style={{marginBottom: '1rem'}}
          />
          <Input.Password
            value={this.state.confirmPassword}
            prefix={<LockTwoTone twoToneColor="#DF3535" />}
            placeholder=" Confirm new password"
            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
            size="large"
            required
            className="loginInput"
          />
        </Modal>
      </>
    );
  }
}