import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import reportWebVitals from "./reportWebVitals";

Amplify.configure(awsconfig);
// https://master.d2dyq0nxuwhtjg.amplifyapp.com/
awsconfig.oauth.redirectSignIn = `https://master.d2dyq0nxuwhtjg.amplifyapp.com/`;
awsconfig.oauth.redirectSignOut = `https://master.d2dyq0nxuwhtjg.amplifyapp.com/`;

// awsconfig.oauth.redirectSignIn = `http://localhost:3000/`;
// awsconfig.oauth.redirectSignOut = `http://localhost:3000/`;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
