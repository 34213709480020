import axios from "axios";

const token = process.env.REACT_APP_SECRET_KEY_DBOTECHEVAL;

export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_TECH_EVAL,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  },
});
