import { useState } from 'react';
import { Space , Modal, Card, Divider ,Typography, Flex, Spin   } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Alert  } from 'antd';

import './search.css';

const { Title } = Typography;

const gridStyle = {
    width: '25%',
    textAlign: 'center',
  };

const ModalApplication = ({ isVisible, handleCancel, applicationType, appDescription, searchValue, setSearchValue, handleSubmitSearch, searchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal 
        title={appDescription}
        open={isVisible} 
        onCancel={handleCancel}
        width={1000}
        style={{ 
            height: 1000,
            overflowY: 'auto' // Adds a scrollbar if the content overflows the specified height
        }}
        footer={[]}

      >
 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: "30px", gap: "10px" }}>
    <Input
        placeholder="Enter your op number"
        prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        style={{ borderRadius: '10px', height: '50px', marginTop: '30px', width: '50%' }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
    />

    <div style={{ width: '15%' }}> {/* Wrapper with fixed width */}
        <Spin size="small" style={{marginTop:"14px"}} spinning={false}>
            <Input
                value="Search"
                type='Button'
                style={{ 
                    borderRadius: '10px', 
                    height: '50px',
                    marginTop: '30px',
                    width: '100%', /* Use 100% to fill the wrapper */
                    cursor: 'pointer'
                }}
                onClick={handleSubmitSearch}
            />
        </Spin>
    </div>
</div>

        <Divider />

       <Card title={searchData?.BillNumber || ""}>
        <table>
          <tbody>
            {Object.entries(searchData)?.filter(([key, value]) => key !== "BillNumber")?.map(([key, value], index) => (
              <tr key={index}>
                  <td>{key}:</td>
                  <td style={{ marginLeft: "10px", color: key === "PostFlag" ? (value === "P" || value === "p") ? "green" : "red" :"" }}>
                  {key === "PostFlag" ? (value === "P" || value === "p") ? "Paid" : "Cancel or Void" : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      </Modal>
    </>
  );

}

export default ModalApplication;