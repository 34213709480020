import { 
    useState 
} from 'react';
import { 
    Row, 
    Col, 
    Card, 
    Badge,

} from 'antd';
import { 
    FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
    faMapPin
  } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

import Modal from "./modal";

import ApiTechevalBe from "../../services/apiTecheval-be";

const { Meta } = Card;

const iconMapping = {
    faMapPin:faMapPin
  };

const app = [
    {name: "checkerOp", description: "Check payment order number"},
]

const Opchecker = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [applicationType, setApplicationType] = useState("");
    const [appDescription, setAppDescription] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [searchData, setSearchData] = useState({});

    const handleShowModal = (appType, appDescription) => {
        setIsModalVisible(true);
        setApplicationType(appType);
        setAppDescription(appDescription);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSearchValue("");
    }

    const handleSubmitSearch = async () => {
        let payload = { DBO_BillingNo: searchValue };
        try {
            const response = await ApiTechevalBe.checkPaymentIfExistforDBO(payload);

            if(response === undefined ) return;

            if(response === 0) {
                alert("No data found");
                setSearchData({});
                setSearchValue("")
                return;
            };

            setSearchData(response["DBO"]);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    return (
        <div>
            <Row gutter={[16, 16]}>
                {app.map((item, index) => (
                    <Col xs={24} sm={12} lg={8} key={item}>
                        <Badge.Ribbon text={String.fromCharCode(65 + 0)}>
                            <Card
                                className="card"
                                style={{ height: '120px', cursor: 'pointer', borderRadius: '10px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'}} 
                                hoverable={true}
                                actions={[]}
                                onClick={() => handleShowModal(item.name, item.description)}
                            >
                                <Meta
                                avatar={<FontAwesomeIcon icon={iconMapping["faMapPin"]}  size="4x" color="#5B5D6B"/>}
                                title={<span style={{ fontWeight: 'normal', color:"#5B5D6B" }}>Checker</span>}
                                description={item.description}
                                style={{padding:"10px"}}
                                />
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                ))}
            </Row>

            <Modal 
              isVisible={isModalVisible}  
              handleCancel={handleCancel} 
              applicationType={applicationType} 
              appDescription={appDescription}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              handleSubmitSearch={handleSubmitSearch}
              searchData={searchData}
            />

        </div>
    )
}

export default Opchecker;