import React from "react";
import { Auth } from "aws-amplify";
import logo from "../../images/management.png"
import Qc from "../../images/qclogo_main.png"
import "./home.css";
import { 
  Menu, 
  Layout,  
  Dropdown,
  Button, 
} from "antd";
import {
 UserOutlined,
 DatabaseOutlined,
 HomeOutlined,
 AppstoreAddOutlined
} from "@ant-design/icons";
import Register from "../user/register";
import ViewProfile from "../profile/profile";
import ManageUser from "../user/manageUser";
import SearchApplication from "../SearchApplication/opchecker";
import Dashboard from "./dashboard";
const { Header, Content, Footer } = Layout;

const user = JSON.parse(localStorage.getItem("user"));
const role = user ? user.attributes["custom:role"] : "";

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = { 
      collapsed: true, 
      content:"", 
      username: `${user?.attributes?.name} ${user?.attributes.family_name}` ,
      selectedItem: "mail",
    };
  }
  
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {

    const items = [
      {
        label: 'Dashboard',
        key: 'mail',
        icon: <HomeOutlined style={{fontSize:"18px"}}/>,
        onClick: () => {
          this.props.history.push("/");
          this.setState({ content: "" })
          this.setState({ selectedItem: "mail" })
        },
      },
      ...(role.toLowerCase() === "admin" ||
          role.toLowerCase() === "dbo admin" ||
          role.toLowerCase() === "superadmin"
          ? [
              {
                label: 'Register User',
                key: 'regUser',
                icon: <UserOutlined style={{fontSize:"18px"}}/>,
                onClick: () => this.setState({ content: "addUser", selectedItem: "regUser"}),
              },
              {
                label: 'Manage User',
                key: 'app',
                icon: <DatabaseOutlined style={{fontSize:"18px"}} />,
                onClick: () => this.setState({ content: "viewUser", selectedItem: "app"}),
              },
              {
                label: 'Other Application',
                key: 'opSearch',
                icon: <AppstoreAddOutlined style={{fontSize:"18px"}} />,
                onClick: () => this.setState({ content: "searchApplication", selectedItem: "opSearch"}),
              },
            ]
          : []
        ),
    ];
    

    const menu = (
      <Menu className="my-dropdown-menu">
        <Menu.Item key="0">
          <ViewProfile role={this.state.role} />
        </Menu.Item>
        <Menu.Item key="1">
          <a href="/settings">Settings</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.props.logout}>Logout</Menu.Item>
      </Menu>
    );
    
    return (
      <>
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout">
            <Header className="site-layout-background" 
             style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems:"center",
                backgroundColor:"#FAFAFA",
                height:"75px",
                borderBottom:"1px solid #D2D4DA",
                boxShadow: "0 2px 8px #f0f1f2",
              }} 
              >
              <span style={{ marginRight: '5px' }}>
                {
                  role.toLowerCase() === "admin" || role.toLowerCase() === "dbo admin" || role.toLowerCase() === "superadmin" ? (
                    <>
                       <img src={logo} alt="logo" style={{width:"50px", height:"50px"}} />
                       <span className="logo_title">UMS</span>
                    </>
                  ) : (
                    <>
                       <img src={Qc} alt="logo" style={{width:"60%", height:"60%"}} className="logoFor"/>
                       <span className="logo_title"></span>
                    </>
                  )
                }
              </span>
               <Dropdown overlay={menu} trigger={['click']}>
                <div>
                  <span style={{color:'#5B5D6B', marginRight:"10px", fontSize:"16px", fontWeight:"bolder"}} className="username">
                  Hi!  {this.state.username}
                  </span> 
                  <span style={{color:'#D2D4DA', marginRight:"10px", fontSize:"18px"}}>|</span> 
                  <Button className="ant-dropdown-link" icon={<UserOutlined />} shape="circle" onClick={e => e.preventDefault()} />
                </div>
              </Dropdown>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: "24px",
                minHeight: 250,
              }}
            >
              <Menu  mode="horizontal" items={items} selectedKeys={[this.state.selectedItem]} style={{
                marginBottom:"35px", 
                marginTop:"20px", 
                fontSize:"16px", 
                color:"#5B5D6B",
                borderBottom:"1px solid #D2D4DA"
               }}
              />
              {this.state.content === "addUser" ? (
                <Register
                  signUp={this.props.signUp}
                  regState={this.props.regState}
                  resend={this.props.resend}
                  confirm={this.props.verify}
                />
              ) : this.state.content === "viewUser" ? (
                 <>
                   <ManageUser />
                 </>
              ) : this.state.content === "searchApplication" ? (
                <>
                  <SearchApplication />
                </>
             ) : (
                <Dashboard />
              )}
            </Content>
          </Layout>
          <Footer style={{ textAlign: 'center' }}></Footer>
        </Layout>
      </>
    );
  }
}
