import { useState } from "react";
import { useHistory } from 'react-router-dom';

import { Input, Row, Col, notification } from "antd";
import {
  MailTwoTone,
  LockTwoTone,
  CodeTwoTone
} from "@ant-design/icons";

import { Auth } from 'aws-amplify';

export default function ChangePassword(){
  const history = useHistory();

  const [username, setUsername] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newPasswordBtn, setNewPasswordBtn] = useState(false);

  const handleCode = (e) => {
    e.preventDefault();

    if(username){
      Auth.forgotPassword(username)
        .then(data => {
          console.log(data);
          setNewPasswordBtn(true);

        })
        .catch(err => console.log(err));
    }else {
      notification["error"]({
        message: "Error",
        description:
          "Enter your username.",
      });
    }
  }

  const handleNewPassword = e => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
    .then(data => {
      console.log(data)
      if(data === "SUCCESS"){
        notification["success"]({
          message: "Success",
          description:
            "Your password was changed. You may proceed to login again.",
        });

        setTimeout(() => {
          history.push("/login")
        }, 2000);

      }
    })
    .catch(err => {
      notification["error"]({
        message: "Error",
        description:
          "Please contact administrator for further instruction.",
      });
      console.log(err)
    });

  }

  return (
    <>
      <div className="mainLogin-div">
        <div className="mainLogin-left">
          <form className="leftInner-login" autoComplete="none">
            <div>Please fill-out the fields to continue</div>
            <div></div>
            <div></div>
            <div>
              {
                newPasswordBtn === false? (
                  <>
                    <Input
                      autoComplete="none"
                      defaultValue={" "}
                      prefix={<MailTwoTone twoToneColor="#DF3535" />}
                      type="email"
                      placeholder=" Your username"
                      name="email"
                      onChange={(e) => setUsername(e.target.value)}
                      size="large"
                      required
                      className="loginInput"
                    />
                  </>
                  ) : ("")
              }
              
              {
                newPasswordBtn === true? (
                  <>
                    <Input
                      autoComplete="none"
                      prefix={<CodeTwoTone twoToneColor="#DF3535" />}
                      type="text"
                      placeholder=" Your verification code"
                      name="verificationCode"
                      onChange={(e) => setVerificationCode(e.target.value)}
                      size="large"
                      required
                      className="loginInput"
                    />
                    <Input.Password
                      name="newPassword"
                      autoComplete="new-password"
                      prefix={<LockTwoTone twoToneColor="#DF3535" />}
                      placeholder=" Your new password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      size="large"
                      required
                      className="loginInput"
                    />
                  </>
                  ) : ("")
              }

              {
                newPasswordBtn === false? (
                  <>
                    <button className="btn" onClick={handleCode}>
                      Send code to email
                    </button>
                  </>
                  ) : ("")
              }

              {
                newPasswordBtn === true? (
                    <>
                      <button className="btn" disabled={loading} onClick={handleNewPassword}>
                        Submit new password
                      </button>
                    </>
                  ) : ("")
              }
              
            </div>
            <div>
              <Row>
                <span>&nbsp;</span>
                <span
                  className="regHere"
                  onClick={() => history.push("/login")}
                >
                  Login instead
                </span>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}