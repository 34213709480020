import { Input } from "antd";
import {
  //   UserOutlined,
  //   LockOutlined,
  MailTwoTone,
  UnlockTwoTone,
} from "@ant-design/icons";
import React from "react";
import "../login/login.css";

export default class Verify extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.verify(this.state);
  };

  handleResend = () => {
    // if (this.state.confirmEmail) {
    this.props.resend(this.state.confirmEmail);
    // } else {
    //   message.error("Email/Username is required");
    // }
  };

  render() {
    return (
      <>
        <div className="mainLogin-div">
          <div className="mainLogin-left">
            <form className="leftInner-login" onSubmit={this.handleSubmit}>
              <div>Verify your account to continue</div>
              <div></div>
              <div></div>
              <div>
                <Input
                  prefix={<MailTwoTone twoToneColor="#DF3535" />}
                  type="email"
                  placeholder=" Your username"
                  name="email"
                  onChange={(e) =>
                    this.setState({ confirmEmail: e.target.value })
                  }
                  size="large"
                  required
                />
                <Input
                  prefix={<UnlockTwoTone twoToneColor="#DF3535" />}
                  placeholder=" Your verification code"
                  type="number"
                  onChange={(e) => this.setState({ code: e.target.value })}
                  size="large"
                  required
                />
                <button className="btn">VERIFY</button>
              </div>
              <div>
                Account already verified?&nbsp;
                <span
                  className="regHere"
                  onClick={() => this.props.history.push("/login")}
                >
                  Login here
                </span>
              </div>
              <div className="resendDiv">
                <span onClick={this.handleResend}>
                  Resend verification code
                </span>
              </div>
            </form>
          </div>
          {/*<div className="mainLogin-right">
            <img
              src="https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png"
              alt="QC"
            />
            <div>CFEI Permit Application</div>
            <div></div>
            <div>
              Quezon City system that will use in barangays to issue CFEI
              certificates to fire victims family. This system can be use to
              generate a report of successful CFEI applications.
            </div>
          </div>*/}
        </div>
      </>
    );
  }
}
