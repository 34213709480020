import React from "react";
import "./home.css";
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBuilding, 
  faBuildingFlag, 
  faBuildingShield, 
  faBolt, 
  faFireExtinguisher, 
  faHouseSignal, 
  faFileCircleCheck, 
  faHandshake, 
  faMapLocation, 
  faBuildingCircleXmark, 
  faMagnifyingGlassLocation,
  faCity,
  faMapPin
} from '@fortawesome/free-solid-svg-icons';
import cards from './cards.json';
import { Row, Col, Card,Badge } from 'antd';

const { Meta } = Card;

// (rye) const to get the icon from fontawesome
const iconMapping = {
  faBuilding: faBuilding,
  faBuildingFlag: faBuildingFlag,
  faBuildingShield: faBuildingShield,
  faBolt: faBolt,
  faFireExtinguisher: faFireExtinguisher,
  faHouseSignal: faHouseSignal,
  faFileCircleCheck: faFileCircleCheck,
  faHandshake: faHandshake,
  faMapLocation: faMapLocation,
  faBuildingCircleXmark: faBuildingCircleXmark,
  faMagnifyingGlassLocation: faMagnifyingGlassLocation,
  faCity:faCity,
  faMapPin:faMapPin
};

const link = localStorage.getItem("link"); // Get the link (adrian050224)
const linkId = link ? link.split("/").pop() : null; // get the last element of the link (rye050324) 

export default class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      currentUser: null,  // Initialize currentUser state
      // Initialize all show states
      ...cards.reduce((acc, card) => ({ ...acc, [card.show]: false }), {}),
      // pushed by makoy050224
      cardShow: null,
      userRole:[],
    };
  }

  async componentDidMount() {
    try {
      // this.getLinkFromLocalStorage(); // from sir makoy050224
      // Get the current authenticated user
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ currentUser: user });  // Set currentUser state

      const info = JSON.parse(localStorage.getItem("user")); // rye042424
  
      // Get departments. Create a set from the card's titles. Check if a department is not in the cards rye042324
      let depts = info?.attributes["custom:department"].split("|");
      const cardTitles = new Set(cards.map(card => card.title));
      let isDeptNotInCards = false;
  
      // Get roles and convert them to lowercase rye042424
      const userRoles = info.attributes["custom:role"].split("|").map(role => role.toLowerCase());

      // updates by makoy050224
      this.setState({ userRole: userRoles }); 
   
      let a = cards.filter(card => {
        return depts.includes(card.title);
      });
      
      this.setState({ cardShow: a }); // updates by makoy050224
  
      // Check if the user has the 'SuperAdmin' or 'Admin' role (case-insensitive) rye042424
      if (userRoles.includes('superadmin') || userRoles.includes('admin')) {
        // If the user is a 'SuperAdmin' or 'Admin', show all cards except "Unknown Department" and "QA" rye042424
        const allCardsShown = cards.reduce((acc, card) => {
          if (card.title !== "Unknown Department" && !card.title.includes("QA")) {
            return { ...acc, [card.show]: true };
          }
          return acc;
        }, {});
        this.setState(allCardsShown);
      } else {
        // Loop through depts and check if dept is in the cards
        for (let i = 0; i < depts.length; i++) {
          const deptShow = depts[i]; // Use the value of show as depts[i] value
  
          // If the department is not in the cards, set the flag to true and break the loop rye042324
          if (!cardTitles.has(deptShow)) {
            isDeptNotInCards = true;
            break;
          }
  
          // Loop through cards and check if show property matches deptShow
          let promises = cards.map((card, j) => {
            return new Promise((resolve, reject) => {
              // console.log(`Checking if ${card.title} matches ${deptShow}`); //(rye) console check if matching happens
              if (card.title === deptShow) {
                // Update the state property dynamically
                this.setState(prevState => ({
                  ...prevState,
                  [card.show]: true
                }), () => {
                  // console.log(`State after updating ${card.show}:`, this.state);  // //(rye) console check to log the state in the callback
                  resolve();
                });
              } else {
                resolve();
              }
            });
          });
  
          await Promise.all(promises);
        }
  
        // If a department is not in the cards, add the default card rye042324
        if (isDeptNotInCards) {
          this.setState(prevState => ({
            ...prevState,
            default: true
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // updates by makoy050224
  // getLinkFromLocalStorage = () => {
  //   const linkVal = localStorage.getItem("link");
  //   if (linkVal) {
  //     const parts = linkVal.split('/');
  //     this.setState({ validateLink: parts[parts.length - 1] });
  //   } else {
  //     console.log("No link found in localStorage");
  //   }
  // }

  render() {
  
    const displayCards = cards.filter(card => this.state[card.show]);
    const role = this.state.userRole;

    let showToCard = "";
    if(this.state.userRole.includes("superadmin") || this.state.userRole.includes("admin")) showToCard =  displayCards;
    else showToCard = this.state.cardShow; 

    return (
      <div>
            <Row gutter={[16, 16]}>
              {showToCard?.sort((a, b) => a.label.localeCompare(b.label)).map((card, index) => (
                <Col xs={24}  
                  sm={index % 2 === 0 ? 12 : 24} 
                  lg={role.includes("superadmin") || role.includes("admin") ? 8 : 8}  
                  key={card.title}
                >
                  {
                    role.includes("superadmin") || role.includes("admin") ? (
                    <a 
                      href={card.link+linkId}
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Badge.Ribbon text={String.fromCharCode(65 + index)}>
                        <Card
                          className="card"
                          style={{ height: '120px', cursor: 'pointer', borderRadius: '10px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'}} 
                          hoverable={true}
                          actions={[]}
                        >
                          <Meta
                            avatar={<FontAwesomeIcon icon={iconMapping[card.image]}  size="4x" color="#5B5D6B"/>}
                            title={<span style={{ fontWeight: 'normal', color:"#5B5D6B" }}>{card.label}</span>}
                            description={card.description ?? ""}
                            style={{padding:"10px"}}
                          />
                        </Card>
                      </Badge.Ribbon>
                    
                    </a>
                    ) : (
                      <a 
                        href={card.link+linkId} 
                      >
                        <Badge.Ribbon text="">
                          <Card
                            className="card"
                            style={{ height: '120px', cursor: 'pointer', borderRadius: '10px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'}} 
                            hoverable={true}
                            actions={[]}
                          >
                            <Meta
                              avatar={<FontAwesomeIcon icon={iconMapping[card.image]}  size="3x"/>}
                              title={<span style={{ fontWeight: 'normal' }}>{card.label}</span>}
                              description={card.description ?? ""}
                              style={{padding:"10px"}}
                            />
                          </Card>
                          </Badge.Ribbon> 
                      </a>
                      )
                    }
                </Col>
              ))}
            </Row>
      </div>
    );
  }
}