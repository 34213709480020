import { useEffect, useState } from "react";
import {
  Checkbox,
  Input,
  notification,
  Select,
  List,
  Modal,
  Button,
  Alert,
  Card,
  Descriptions,
  Row,
  Col,
  DatePicker
} from "antd";
import { SmileOutlined } from "@ant-design/icons";
import moment from "moment";

import RecaptchaRegister from "./recaptchaRegister.js";

import "./register.css";

const { Option } = Select;

export default function Register({register}) {
  const [departmentX, setDepartmentX] = useState([]);
	const [divisionX, setDivisionX] = useState([]);
	const [sectionUnit, setSectionUnit] = useState([]);
	const [sectionUnitX, setSectionUnitX] = useState("");
	const [assignedDept, setAssignedDept] = useState("Select Assigned Department");
	const [dept, setDept] = useState(null);
	const [divisionN, setDivisionN] = useState("");
	const [disabledSection, setdisabledSection] = useState(false);
	const [role, setrole] = useState("UNASSIGNED");
	const [setOpenModal, setsetOpenModal] = useState(false);
	const [status, setstatus] = useState(0);
	const [isMaintenance, setisMaintenance] = useState(true);
	const [captcha, setcaptcha] = useState(null);
	const [showButton, setshowButton] = useState(true);
  const [disabled, setdisabled] = useState(false);
  const [date, setdate] = useState(null);
  const [date2, setdate2] = useState(null);
  const [day, setday] = useState(null);
  const [fname, setfname] = useState(null);
  const [mname, setmname] = useState(null);
  const [lname, setlname] = useState(null);
  const [contact, setcontact] = useState(null);
  const [gender, setgender] = useState(null);
  const [birthday, setbirthday] = useState(null);
  const [username, setusername] = useState(null);
  const [password, setpassword] = useState(null);
  const [cpassword, setcpassword] = useState(null);
  const [district, setdistrict] = useState(null);
  
  useEffect(() => {
    let pattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_.+/=]*$/g;
    if (pattern.test(localStorage.getItem("token"))) {
      window.location.pathname = "/";
    }

    setDepartmentX([
      {
        department: "Office of the City Mayor",
        division: [
          {
            divisionName: "Administrative",
            sectionUnit: [
              "Correspondence/Clerical Section",
              "General Services Section",
              "Records Management Section",
              "Finance, Property and Supply Section",
              "Others",
            ],
          },
          {
            divisionName: "Quezon City Persons With Disability Affairs",
            sectionUnit: [],
          },
          {
            divisionName:
              "Quezon City Disaster Risk Reduction and Management Office",
            sectionUnit: [
              "Administration and Training Section",
              "Research and Planning Section",
              "Operations and Warning Section",
              "Others",
            ],
          },
          {
            divisionName: "City Real Estate Management and Control Office",
            sectionUnit: [
              "Management Section",
              "Operations Section",
              "Others",
            ],
          },
          {
            divisionName: "Solo Parents Affairs Office",
            sectionUnit: ["Others"],
          },
          {
            divisionName: "Others",
            sectionUnit: [
              "Intern Audit Service",
              "Gender and Development Council Office",
              "Task Force for Transport and Traffice Management",
              "Task Force Disiplina",
              "Quezon City Sustainable Development Affairs Unit",
              "Quezon City Sustainable Development Affairs Unit",
              "Task Force on Control, Prevention, and Removal of illegal Structures and squatting",
              "Task Force on Food Security",
              "Quezon City Bangsamoro Affairs Service",
              "Education Affairs Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Office of the City Councilors",
        division: [
          {
            divisionName: "District I",
            sectionUnit: [],
          },
          {
            divisionName: "District II",
            sectionUnit: [],
          },
          {
            divisionName: "District III",
            sectionUnit: [],
          },
          {
            divisionName: "District IV",
            sectionUnit: [],
          },
          {
            divisionName: "District V",
            sectionUnit: [],
          },
          {
            divisionName: "District VI",
            sectionUnit: [],
          },
          {
            divisionName: "District VII",
            sectionUnit: [],
          },
          {
            divisionName: "Sectoral Representatives",
            sectionUnit: [
              "Liga Ng Mga Barangay",
              "Sangguniang Kabataan",
              "Office of the Sangguniang Panlungsod Member III"
            ],
          },
        ],
      },
      {
        department: "Office of the Vice Mayor",
        division: [
          {
            divisionName: "Others",
            sectionUnit: ["Technical", "Youth and Sports", "Others"],
          },
        ],
      },
      {
        department: "Office of the City Administrator",
        division: [
          {
            divisionName: "Quezon City ID",
            sectionUnit: ["Others"],
          },
          {
            divisionName: "Administrative Division",
            sectionUnit: ["Others"],
          },
          {
            divisionName: "Fiscal Management Division",
            sectionUnit: ["Others"],
          },
          {
            divisionName: "Management and Organization Division",
            sectionUnit: ["Others"],
          },
          {
            divisionName:
              "Operations, Coordinations, and Monitoring Division",
            sectionUnit: ["Others"],
          },
          { divisionName: "Technical Division", sectionUnit: ["Others"] },
          { divisionName: "Others", sectionUnit: ["Others"] },
        ],
      },
      {
        department:
          "Office of the Secretary to the Sanggunian Panlungsod (City Secretary)",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "General Services Section",
              "Personnel Section",
              "Finance and Accounting Section",
              "Finance and Accounting Section",
              "Others",
            ],
          },
          {
            divisionName: "Legislative Services Division",
            sectionUnit: [
              "Legislative Research Section",
              "Ordinance and Resolution Section",
              "Agenda and Briefing Section",
              "Reference and Archives Section",
              "Others",
            ],
          },
          {
            divisionName: "Journal and Minutes Division",
            sectionUnit: [
              "Journal and Transcript Section",
              "Minutes Section",
              "Committee Affairs Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Planning And Development Department",
        division: [
          {
            divisionName: "Social Development Planning Division",
            sectionUnit: [
              "Demography and Health",
              "Education and Sports",
              "Social Welfare and Protective Services",
              "Others",
            ],
          },
          {
            divisionName: "Economic Development Planning Division",
            sectionUnit: [
              "Trade and Industry",
              "Labor and Employment",
              "Others",
            ],
          },
          { divisionName: "Special Projects Division", sectionUnit: [] },
          {
            divisionName:
              "Disaster Risk Reduction Management/Climate Change Adaption",
            sectionUnit: [
              "Environmental Quality ",
              "Database Management",
              "Others",
            ],
          },
          {
            divisionName: "Land Use and Infrastructure Division",
            sectionUnit: ["Land Use", "Infrastructure"],
          },
          {
            divisionName: "Institutional Development Planning Division",
            sectionUnit: [
              "Organization and Management",
              "People's Participation",
              "Others",
            ],
          },
          {
            divisionName: "Zoning and Urban Design Division",
            sectionUnit: [],
          },
          {
            divisionName:
              "Subdivision Administration and Regulation Division",
            sectionUnit: ["Subdivision", "Others"],
          },
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Personnel and Budget",
              "Property, Supplies and Records",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Legal Department",
        division: [
          {
            divisionName: "Trial and Investigation Division",
            sectionUnit: [],
          },
          { divisionName: "Opinion and Research Division", sectionUnit: [] },
          {
            divisionName: "Records and Information Management Division",
            sectionUnit: [],
          },
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Legal Aide and Enforcement Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Civil Registry Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Records Management and Archiving Division",
            sectionUnit: [
              "Information and Data Management Section",
              "Others",
            ],
          },
          { divisionName: "Birth Registration Division", sectionUnit: [] },
          { divisionName: "Marriage Registration Division", sectionUnit: [] },
          { divisionName: "Death Registration Division ", sectionUnit: [] },
          {
            divisionName: "Public Cemetery Management Division",
            sectionUnit: [
              "Baesa Public Cemetery",
              "Novaliches Public Cemetery",
              "Bagbag Public Cemetery",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City General Services Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Human Resource Management Section",
              "Fiscal Management Section",
              "General Administrative Section",
              "Others",
            ],
          },
          {
            divisionName: "Moveable Property Management and Control Division",
            sectionUnit: [
              "Programming and Utilization Section",
              "Inventory",
              "Disposal Section",
              "Technical Inspection Section",
              "Others",
            ],
          },
          {
            divisionName: "Central Warehouse Management Division",
            sectionUnit: [
              "Supplies Receiving and Programming Section",
              "Storage and Processing Section",
              "Others",
            ],
          },
          {
            divisionName: "Building and Grounds Management Division",
            sectionUnit: [
              "Building and Facilities Maintenance Section",
              "Security and Housekeeping Services Section",
              "Equipment Maintenance Section",
              "Others",
            ],
          },
          {
            divisionName: "Records Management and Control Division",
            sectionUnit: [
              "Current Records and Mail Section",
              "Records Storage Section",
              "Records Archival Section",
              "Others",
            ],
          },
          {
            divisionName: "Motorpool Division",
            sectionUnit: [
              "Motor Vehicles Maintenance Section",
              "Dispatching Section",
            ],
          },
          {
            divisionName: "Fixed Assets Management and Control Division",
            sectionUnit: [
              "Land Inventory Section",
              "Building and Improvement Inventory Section",
              "Research and Disposition Section",
              "Others",
            ],
          },
          {
            divisionName: "Streetlights Management & Services Division",
            sectionUnit: [
              "Operation and Maintenance Section",
              "Planning and Monitoring Section",
              "Streetlight Installation and Repair Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Budget Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          { divisionName: "Budget Development Division", sectionUnit: [] },
          {
            divisionName: "Budget Operations and Control Division",
            sectionUnit: [],
          },
          {
            divisionName:
              "Budget Management and Information Service Division",
            sectionUnit: [],
          },
          {
            divisionName: "Barangay Operation Affairs Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Accounting Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Records Management Section",
              "EDP Database Section",
              "Others",
            ],
          },
          {
            divisionName: "Fiscal Management and Control Division",
            sectionUnit: [
              "Pre-Audit Section",
              "Fund Control Section",
              "Technical Inspection Section",
              "Others",
            ],
          },
          {
            divisionName: "Financial Accounts and Analysis Division",
            sectionUnit: [
              "Financial Accounts Section",
              "Financial Analysis Section",
              "Miscellaneous Accounts Section",
              "Others",
            ],
          },
          {
            divisionName: "Payrolls, Bills and Remittance Division",
            sectionUnit: [
              "Payroll Section",
              "Bill and Remittance Section",
              "Others",
            ],
          },
          {
            divisionName: "Revenue Abstract and Statistics Division",
            sectionUnit: [
              "Revenue Abstract Section",
              "Revenue Statistics and Analysis Section",
              "Others",
            ],
          },
          {
            divisionName: "Barangay Accounting Division",
            sectionUnit: [
              "Bookkeeping Section",
              "Processing Section",
              "Others",
            ],
          },
          {
            divisionName: "Internal Audit Division",
            sectionUnit: [
              "Financial Audit Section",
              "System Audit Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Department of Public Order and Safety",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Traffic Operations Division",
            sectionUnit: [
              "Traffic Planning Section",
              "Traffic Enforcement Section",
              "Others",
            ],
          },
          {
            divisionName: "Security Division",
            sectionUnit: [
              "Personnel and Document Security Section",
              "Physical Security Section",
              "Others",
            ],
          },
          {
            divisionName: "Disaster Control Division",
            sectionUnit: [
              "Disaster Preparedness Section",
              "Rescue and Relief Section",
              "Others",
            ],
          },
          {
            divisionName:
              "Anti-Squatting Enforcement and Relocation Division",
            sectionUnit: [
              "Squatter Demolition Section",
              "Squatter Relocation Section",
              "Others",
            ],
          },
          {
            divisionName: "Tricycle Regulation Division",
            sectionUnit: [
              "Processing and Research Section",
              "Inspection and Monitoring Section",
              "Administration, Education and Data Management Section",
              "Research, Planning and Development Section",
              "Bicycle and Active Transport Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Social Services Development Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Community Outreach Division",
            sectionUnit: [
              "Family Development Section",
              "Youth Development Section",
              "Child Development Section",
              "Others",
            ],
          },
          {
            divisionName: "Vocational Development Division",
            sectionUnit: [
              "Skills Training Section",
              "Employment and Placement Section",
              "Others",
            ],
          },
          {
            divisionName: "Residential and Rehabilitation Division",
            sectionUnit: ["Molave Youth Home", "Others"],
          },
          {
            divisionName: "Reception and Action Center for Mendicants ",
            sectionUnit: ["Home for the Aged and Hospice Section", "Others"],
          },
          {
            divisionName: "Welfare and Relief Division",
            sectionUnit: [
              "Public Assistance Section",
              "Volunteer and Auxiliary Section",
              "Others",
            ],
          },
          { divisionName: "Public Assistance Division", sectionUnit: [] },
          { divisionName: "Special Project Division", sectionUnit: [] },
          {
            divisionName: "Child Welfare and Protection Office",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department:
          "Department of Sanitation and Cleanup Works of Quezon City",
        division: [
          {
            divisionName: "Waste Management Operations Division",
            sectionUnit: [
              "Waste Management Operations Division",
              "Disaster Relief and Cleanup Section",
              "Others",
            ],
          },
          {
            divisionName: "Compliance and Permits Division",
            sectionUnit: [
              "Permits and Evaluation Section",
              "Compliance and Enforcement Section",
              "Others",
            ],
          },
          {
            divisionName:
              "Administrative Affairs and Property Management Division",
            sectionUnit: [
              "Administrative Affairs Section",
              "Property Management Section",
              "Others",
            ],
          },
          {
            divisionName: "Public Affairs and Special Division",
            sectionUnit: ["Special Concerns Section", "Others"],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department:
          "Climate Change and Environmental Sustainability Department",
        division: [
          {
            divisionName: "Climate change Mitigation Divisions",
            sectionUnit: [
              "Low Carbon Development Section",
              "Environmental Quality Section",
              "Others",
            ],
          },
          {
            divisionName: "Climate Change Adaptation Division",
            sectionUnit: [
              "Biodiversity Management and Food Security Section",
              "Resource Efficiency Section",
              "Others",
            ],
          },
          {
            divisionName: "Climate Change and Environment Education Division",
            sectionUnit: [
              "Institutional Capacity Development Section",
              "Community and Stakeholder Engagement Section",
              "Others",
            ],
          },
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Human Resource Management Section",
              "Budget and Finance Section",
              "Records Management and General Services Section",
              "Others",
            ],
          },
          {
            divisionName: "Others",
            sectionUnit: [
              "Management Information System Unit",
              "Performance Monitoring Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Parks Development and Administration Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Construction and Maintenance Division",
            sectionUnit: [],
          },
          { divisionName: "Landscape Development Division", sectionUnit: [] },
          { divisionName: "Nursery and Arboretum Division", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Market Development and Administration Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Personnel Section",
              "Fiscal and Statistical Section",
              "Market Inspection Section",
              "Others",
            ],
          },
          { divisionName: "Public Market Division", sectionUnit: [] },
          { divisionName: "Private Market Division", sectionUnit: [] },
          { divisionName: "Technical Services Division", sectionUnit: [] },
          {
            divisionName: "Hwaker’s Division",
            sectionUnit: [
              "Hawker’s Permit Section",
              "Hawker’s Inspection Section",
              "Others",
            ],
          },
          {
            divisionName: "Others",
            sectionUnit: [
              "Management Information system Unit",
              "Security Unit",
              "Maintenance Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Office of the City Assessor",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Personnel Section",
              "Records Section",
              "Fiscal Management Section",
              "Supply Section",
              "Others",
            ],
          },
          {
            divisionName: "Assessment Records Management Division",
            sectionUnit: [
              "Assessment Processing Section",
              "Property Declaration Section",
              "Records Control Section",
              "Others",
            ],
          },
          {
            divisionName: "Electronic Data Processing Division",
            sectionUnit: [
              "Data Preparation Section",
              "Computer Operation Section",
              "System Analysis and Support Section",
              "Others",
            ],
          },
          {
            divisionName: "Tax/Mapping Operations Division",
            sectionUnit: [
              "Mapping section",
              "Tax Mapping Maintenance Section",
              "Property Identification Section",
              "Others",
            ],
          },
          {
            divisionName: "Appraisal Division",
            sectionUnit: [
              "Building Appraisal Section",
              "Land Appraisal Section",
              "Machinery Appraisal Section",
              "Others",
            ],
          },
          {
            divisionName: "Property Valuation Standard Division",
            sectionUnit: [
              "Market Valuation Section",
              "Building Plan Record Section",
              "Assessment Valuation Control Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Office of the City Treasure",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Records Section",
              "Personnel",
              "Fiscal Examination Section",
              "General Section",
              "Others",
            ],
          },
          {
            divisionName: "Inspection and Examination Division",
            sectionUnit: ["Inspection", "Examination", "Others"],
          },
          { divisionName: "EDP Division", sectionUnit: [] },
          { divisionName: "Cash Division", sectionUnit: [] },
          {
            divisionName: "Taxes and Fees Division",
            sectionUnit: [
              "Assesment",
              "Collection",
              "Market Collection",
              "Revenue Taxes and Fees Verification",
              "Miscellaneous Taxes",
              "Residence Tax",
              "Tax Delinquency and Verification",
              "Others",
            ],
          },
          {
            divisionName: "Real Estate Division",
            sectionUnit: [
              "Collection",
              "Revenue Assessment",
              "Real Property Taxes and Verification",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Quezon City Engineering Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Personnel Section",
              "Records Section",
              "Accounting Section",
              "Property and Supply Section",
              "General Services Section",
              "Electronic Data Section",
              "Others",
            ],
          },
          {
            divisionName: "Vertical Infra-Projects Division",
            sectionUnit: [],
          },
          {
            divisionName: "Horizontal Infra-Projects Division",
            sectionUnit: ["Utilities Section", "Others"],
          },
          { divisionName: "Road Maintenance Division", sectionUnit: [] },
          {
            divisionName: "Interim Building Maintenance Division",
            sectionUnit: [],
          },
          { divisionName: "Motorpool Division", sectionUnit: [] },
          { divisionName: "Planning and Programming", sectionUnit: [] },
          {
            divisionName: "Others",
            sectionUnit: ["Project Monitoring Unit", "Others"],
          },
        ],
      },
      {
        department: "Department of the Building Official",
        division: [
          {
            divisionName: "Permit Processing for Simple Application Division",
            sectionUnit: [
              "Construction Permit Processing",
              "Final Permit Processing",
              "Compliance",
              "Others",
            ],
          },
          {
            divisionName: "Construction Permit Division",
            sectionUnit: ["Green Building Section", "Regular Section"],
          },
          {
            divisionName: "Final Permit Division",
            sectionUnit: ["Regular Processing", "Green Building", "Others"],
          },
          {
            divisionName: "Signboard & Utilities Division",
            sectionUnit: [
              "Sign Board Processing Section",
              "Utilities Excavation/DeepWell",
              "Others",
            ],
          },
          {
            divisionName: "Investigation and Adjucation",
            sectionUnit: ["Adjucation", "Inspection", "Others"],
          },
          {
            divisionName:
              "Policy, Programming & System Administration Division",
            sectionUnit: [
              "Policy, Programming and Technical Compliance",
              "System Administration",
              "Administration & Operation Monitoring",
              "Others",
            ],
          },
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Receiving & Validation",
              "Assessment and Billing",
              "Releasing",
              "Administrative Support",
              "Others",
            ],
          },
          {
            divisionName: "Annual Building Inspection Division",
            sectionUnit: [
              "Administrative",
              "Annual Inspection",
              "On-going Construction",
              "Others",
            ],
          },
          {
            divisionName: "Others",
            sectionUnit: [
              "Personnel and Budget Unit",
              "Supply and Procurement Unit",
              "Administrative Relations Unit",
              "Records Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Quezon City Health Department",
        division: [
          { divisionName: "Office of the City Health Officer", sectionUnit: [] },
          { divisionName: "Office of the Assistant City Health Officer", sectionUnit: [] },
          { divisionName: "Administrative Division", sectionUnit: [
              "PERSONNEL",
              "BUDGET",
              "PROPERTY",
              "PHARMACY",
          ]},
          { divisionName: "Field Operation Division", sectionUnit: [
            "ALL FIELD"
          ]},
          { divisionName: "Planning, Evaluation, Research and Training Division", sectionUnit: [
            "PLANNING",
            "TRANING",
            "CESU",
            "HEPO"
          ]},
          { divisionName: "Environmental Sanitation Division", sectionUnit: [] },
          { divisionName: "Nursing Division", sectionUnit: [] },
          { divisionName: "Dental Division", sectionUnit: [] },
          { divisionName: "Nutrition Section", sectionUnit: [] },
          { divisionName: "Special Services Division", sectionUnit: [] },
          { divisionName: "Midwifery Section", sectionUnit: [] },
          { divisionName: "Health Information Management Section", sectionUnit: [] },
          { divisionName: "Laboratory Section", sectionUnit: []},
        ],
      },
      {
        department: "Quezon City General Hospital",
        division: [
          {
            divisionName:
              "Planning Development Education and Research Department",
            sectionUnit: [
              "Management Information System Section",
              "Medical Services",
              "Others",
            ],
          },
          { divisionName: "ER-OPD Emergency Department", sectionUnit: [] },
          { divisionName: "Medical Department", sectionUnit: [] },
          { divisionName: "Psychiatry Department", sectionUnit: [] },
          {
            divisionName: "Surgery Department",
            sectionUnit: ["Surgery Department", "Others"],
          },
          {
            divisionName: "Ear, Nose and Throat Department",
            sectionUnit: [],
          },
          { divisionName: "Ophthalmology Department", sectionUnit: [] },
          { divisionName: "Orthopedic Department", sectionUnit: [] },
          {
            divisionName: "Obstetrics and Gynecology Department",
            sectionUnit: [],
          },
          {
            divisionName: "Paediatrics Department ",
            sectionUnit: ["Human Milk Bank Section", "Others"],
          },
          { divisionName: "Ancillary Medical Services", sectionUnit: [] },
          { divisionName: "Anaesthesiology Department ", sectionUnit: [] },
          { divisionName: "Laboratory Department", sectionUnit: [] },
          { divisionName: "Radiology Department", sectionUnit: [] },
          {
            divisionName: "Community Ambulatory Health Care Department ",
            sectionUnit: [],
          },
          { divisionName: "Dental Department", sectionUnit: [] },
          { divisionName: "Pharmacy Department", sectionUnit: [] },
          { divisionName: "Medical Records Department", sectionUnit: [] },
          {
            divisionName: "Medical Social Services Department",
            sectionUnit: [],
          },
          { divisionName: "Nursing Department", sectionUnit: [] },
          {
            divisionName: "Operating Room and Recovery Department",
            sectionUnit: [],
          },
          { divisionName: "Ward Department", sectionUnit: [] },
          {
            divisionName: "Delivery Room and Nursery Department",
            sectionUnit: [],
          },
          { divisionName: "Dietetic Department", sectionUnit: [] },
          {
            divisionName: "Administrative Division",
            sectionUnit: ["Records", "Cash", "Property and Supply", "Others"],
          },
          { divisionName: "Personnel Division", sectionUnit: [] },
          { divisionName: "Accounting Division", sectionUnit: [] },
          { divisionName: "Engineering Services", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Business Affairs Coordinating Office",
        division: [{
          divisionName: "Others",
          sectionUnit: []
        }],
      },
      {
        department: "Board of Assessment Appeals",
        division: [{
          divisionName: "Others",
          sectionUnit: []
        }],
      },        {
        department: "Business Permits and Licensing Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Personnel and General Services",
              "Finance and Budget",
              "Others",
            ],
          },
          {
            divisionName:
              "Management Information System, Records and Archiving Division",
            sectionUnit: [
              "Management Information System",
              " Records and Archiving",
              "Others",
            ],
          },
          {
            divisionName: "Business Permit Division",
            sectionUnit: ["Business Permit", "Occupational Permit", "Others"],
          },
          {
            divisionName: "Liquor Licensing Division",
            sectionUnit: ["Licensing", "Regulatory", "Others"],
          },
          {
            divisionName: "Inspection and Verification Division",
            sectionUnit: ["Inspection", "Verification", "Others"],
          },
          {
            divisionName: "Enforcement and Adjudication Division",
            sectionUnit: ["Enforcement", "Adjudication", "Others"],
          },
          { divisionName: "Novaliches District Center", sectionUnit: [] },
        ],
      },
      {
        department:
          "Quezon City Small Business and Cooperatives Developement and Promotions Office",
        division: [
          {
            divisionName:
              "Planning , Program Development and Monitoring Division",
            sectionUnit: [
              "Policy and Planning Section",
              "Capacity Building and Monitoring Section",
              "Financing and Marketing Section",
              "Others",
            ],
          },
          {
            divisionName: "Information Management Division",
            sectionUnit: [
              "Information Capture Structure and Sharing Section",
              "Database Management Section",
              "Others",
            ],
          },
          {
            divisionName:
              "Cooperatives Development and Business Resources Division",
            sectionUnit: [
              "Cooperatives Development Section",
              "Business Resources Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Quezon City Public Libary",
        division: [
          {
            divisionName: "Reader’s Services Division",
            sectionUnit: [
              "Reference Section",
              "Filipiniana, Local History and Archives Section",
              "Law Research Section",
              "Periodicals Section",
              "Management Information System Section",
              "Others",
            ],
          },
          {
            divisionName: "Technical Services Division",
            sectionUnit: [
              "Collective Development Section",
              "Cataloging Section",
              "Binding and Preservation Section",
              "Others",
            ],
          },
          {
            divisionName: "Library Extension Division",
            sectionUnit: [
              "Recreational, Educational and Social Activity Section",
              "Recreational, Educational and Social Activity Section",
              "Others",
            ],
          },
          { divisionName: "District Libraries Division", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Barangay and Community Relations Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Operations and Monitoring Division",
            sectionUnit: [],
          },
          {
            divisionName: "Planning and Programming Division",
            sectionUnit: [],
          },
          {
            divisionName: "Technical and Research Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department:
          "Housing, Community Development and Resettlement Department",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Central Records and Human Resource Section",
              "Budget and Supply Section",
              "Others",
            ],
          },
          {
            divisionName: "Housing and Resettlement Division",
            sectionUnit: [
              "Community Mortgage and Program Section",
              "Community Development Section",
              "Direct Sales Section",
              "Others",
            ],
          },
          {
            divisionName: "Census and Planning Division",
            sectionUnit: [
              "Census and Survey Section",
              "Technical Section",
              "Others",
            ],
          },
          {
            divisionName: "Support Service Division",
            sectionUnit: [
              "Accounts Monitoring and Management Section",
              "Basic Utilities Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Information Technology Development Department",
        division: [
          {
            divisionName: "Network and Technical Maintenance Division",
            sectionUnit: [],
          },
          { divisionName: "Database Management Division", sectionUnit: [] },
          {
            divisionName: "Systems Development Management Division",
            sectionUnit: [],
          },
          {
            divisionName:
              "Project Research and Project Research and Development Division",
            sectionUnit: [],
          },
          {
            divisionName: "Administrative Division",
            sectionUnit: ["Others"],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Procurement Department",
        division: [
          {
            divisionName: "Documentation and Administrative Support Division",
            sectionUnit: [],
          },
          { divisionName: "Technical Services Division", sectionUnit: [] },
          {
            divisionName: "Procurement Database and Management Division",
            sectionUnit: [],
          },
          {
            divisionName: "Price Standardization and Monitoring Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Quezon City University",
        division: [
          { divisionName: "Administration and Finance", sectionUnit: [] },
          { divisionName: "Academic Affairs", sectionUnit: [] },
          {
            divisionName: "Research, Extension, Planning and Linkages",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Public Employment Service Office (PESO)",
        division: [
          { divisionName: "Employment Services Division", sectionUnit: [] },
          {
            divisionName: "Labor Relations and Standard Division",
            sectionUnit: [],
          },
          { divisionName: "Special Projects Division", sectionUnit: [] },
          {
            divisionName: "Labor Market Information System Division",
            sectionUnit: [],
          },
          { divisionName: "Migration Services Division", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Public Affairs and Information Services Department",
        division: [
          { divisionName: "Editorial Services Division", sectionUnit: [] },
          { divisionName: "Media Relations Division", sectionUnit: [] },
          { divisionName: "Digital Media Division", sectionUnit: [] },
          {
            divisionName: "Visual Production Services Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Radio Communications Service",
        division: [{ divisionName: "Others", sectionUnit: [] }],
      },
      {
        department: "Quezon City Tourism Department",
        division: [
          { divisionName: "Tourism Services Division", sectionUnit: [] },
          { divisionName: "Tourism Coordination Division", sectionUnit: [] },
          { divisionName: "Tourism Promotions Division", sectionUnit: [] },
          { divisionName: "Tourism Planning Division", sectionUnit: [] },
          { divisionName: "Quezon City Symphonic Band", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Architect Department",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          {
            divisionName: "Planning and Programming Division",
            sectionUnit: [],
          },
          { divisionName: "Architectural Design Division ", sectionUnit: [] },
          {
            divisionName: "Supervision and Monitoring Division",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "City Veterinary Department",
        division: [
          {
            divisionName: "Food Hygiene and Regulatory Division",
            sectionUnit: [],
          },
          { divisionName: "City Pound Division", sectionUnit: [] },
          {
            divisionName: "Animal Care & Disease Control Division",
            sectionUnit: [],
          },
          { divisionName: "Administrative Division", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Quezon City Anti-Drug Council (QCADAC)",
        division: [
          {
            divisionName: "Others",
            sectionUnit: [
              "Technical Research and Legal Services Section",
              "In-take, Referral and Evaluation Section",
              "Intelligence, Surveillance and Operation Section",
              "Administrative Section",
              "Others",
            ],
          },
        ],
      },
      {
        department:
          "Quezon City Drug Treatment and Rehabilitation Center (TAHANAN)",
        division: [
          {
            divisionName: "Others",
            sectionUnit: [
              "Technical Research and Legal Services Section",
              "Rehabilitation and Residential Section",
              "dministrative Services Section",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Quezon City Center for Children with Disabilities",
        division: [
          {
            divisionName: "Others",
            sectionUnit: [
              "Administrative Staff Support Unit",
              "Health Unit",
              "Education Unit",
              "Livelihood Unit",
              "Social and Empowerment Unit",
              "Others",
            ],
          },
        ],
      },

      {
        department: "Quezon City Youth Development Office",
        division: [
          {
            divisionName: "Policy Research, Program Development and Planning",
            sectionUnit: [
              "Policy Research and Program Development",
              "Planning",
              "Others",
            ],
          },
          {
            divisionName: "Youth Welfare Programs and Operations",
            sectionUnit: [
              "Youth Welfare Programs",
              "Youth Welfare Operations",
              "Others",
            ],
          },
          {
            divisionName: "Management Information Systems ",
            sectionUnit: [],
          },
          {
            divisionName:
              "Operations, Coordinations, and Monitoring Division",
            sectionUnit: [],
          },
          {
            divisionName: "Operation and Administrative Staff",
            sectionUnit: [],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department:
          "Quezon City Protection Center for Victims/Survivors of Gender Based Violence And Abuse",
        division: [
          {
            divisionName: "Others",
            sectionUnit: [
              "Administrative Unit",
              "Counselling and Case Intake Unit",
              "Medical Support Unit",
              "Legal and Security Unit",
              "Advocacy and Network Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Quezon City People's Law Enforcement Center Board",
        division: [{ divisionName: "Others", sectionUnit: [] }],
      },
      {
        department: "Office of the City Prosecutor",
        division: [
          { divisionName: "Administrative Division", sectionUnit: [] },
          { divisionName: "Records and Docket Division", sectionUnit: [] },
          { divisionName: "Secretarial Division", sectionUnit: [] },
          { divisionName: "General Services Division", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Division of City Schools",
        division: [
          {
            divisionName: "Administrative Division",
            sectionUnit: [
              "Records Section",
              "Personnel Section",
              "Accounting Section",
              "Infrastructure Development Section",
              "Property and Supply Section",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Local Economic Investment Promotions Office",
        division: [
          { divisionName: "Promotions and Marketing", sectionUnit: [] },
          { divisionName: "Operations", sectionUnit: [] },
          { divisionName: "Technical Support ", sectionUnit: [] },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Human Resource Management",
        division: [
          {
            divisionName:
              "Human Resource Development And Administrative Support",
            sectionUnit: ["Human Resource Development", "Others"],
          },
          {
            divisionName: "Personnel Actions",
            sectionUnit: [
              "Appointment and Personnel Movement",
              "Personnel Records",
              "Others",
            ],
          },
          {
            divisionName: "Compensation and Benefits",
            sectionUnit: [
              "Employee Compensation",
              "Employee Benefits",
              "Others",
            ],
          },
          {
            divisionName: "Recruitment and Career Management",
            sectionUnit: ["Recruitment", "Career Management ", "Others"],
          },
          {
            divisionName:
              "Human Resource Planning and Organizational Development",
            sectionUnit: [
              "Human Resource Planning",
              "Organizational Development",
              "Others",
            ],
          },
          { divisionName: "Others", sectionUnit: [] },
        ],
      },
      {
        department: "Others",
        division: [{ divisionName: "", sectionUnit: [""] }],
      },
      {
        department: "Person with Disability Affairs Office",
        division: [
          {
            divisionName: "Others",
            sectionUnit: [
              "Monitoring and Data Management Unit",
              "Learning and Capacity Development Unit",
              "Operations Unit",
              "Others",
            ],
          },
        ],
      },
      {
        department: "Rosario Maclang Bautista General Hospital",
        division: [{ divisionName: "Others", sectionUnit: ["Others"] }],
      },
      {
        department: "Novaliches District Hospital",
        division: [{ divisionName: "Others", sectionUnit: ["Others"] }],
      },
      {
        department: "Quezon City Fire District",
        division: [{ divisionName: "Others", sectionUnit: ["Others"] }],
      },
      {
        department:
          "Task Force for Transport and Traffic Management (TF-TTM)",
        division: [{ divisionName: "Others", sectionUnit: ["Others"] }],
      },
      {
        department: "Investment Affairs Office",
        division: [{ divisionName: "Others", sectionUnit: ["Others"] }],
      },
    ]);

    let date = moment().format("MMMM Do YYYY");
    let date2 = moment().format("YYYY-DD-MM");
    let day = moment().format("dddd");
    setdate(date)
    setday(day)
    setdate2(date2)

    setdisabled(false)

  }, []);


  const handleIsMaintenance = () => {
    setisMaintenance(false)
  };

  const changeDivision = (event) => {
    setDivisionN(event)
    const d = departmentX.filter((d) => d.department === assignedDept)[0].division;
    const x = d.find((stat) => stat.divisionName === event).sectionUnit;
    if (event === "Others" && x.length > 0) {
      setSectionUnit(x)
      setSectionUnitX("Select Section/Unit")
      setdisabledSection(false)

    } else {
      if (x.length > 0) {
        setSectionUnit(d.find((stat) => stat.divisionName === event).sectionUnit)
        setSectionUnitX("Select Section/Unit")
        setdisabledSection(false)
      } else {
        setdisabledSection(true)
        setSectionUnitX("Others")
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const d = {
      name: fname,
      mname: mname,
      lname: lname,
      username: username,
      password: password,
      dept: dept,
      gender: gender,
      contact: contact,
      birthday: birthday,
      dept: dept
    };

    // const assignedDeptStatus = assignedDept;
    // const sectionAssignedStatus = sectionUnitX;
    // const divisionNStatus = divisionN;
    // const homeDept = dept;
    setdisabled(true)

    if (d.gender == null || d.dept == null || d.birthday == null) {
      notification["error"]({
        message: "Error",
        description: "Please complete the fields below to continue.",
      });
      setdisabled(false)
    } else if (cpassword !== password) {
      notification["error"]({
        message: "Error",
        description: "Confirm password and password does not match.",
      });
      setdisabled(false)
    } else {
      if (status === 0) {
        setdisabled(false)
        setsetOpenModal(true)
      }
    }
  };

  const handleHome = (e) => {
    try {
      setDept(e.target.value)
    } catch (err) {
      console.log(err);
    }
  }

  const showModal = () => {
    setsetOpenModal(true)
    setstatus(0)
  };

  const handleOk = () => {
    setsetOpenModal(false)
    setstatus(1) 
  };

  // const handleCaptcha = () => {
  //   if(captcha){
  //     props.signUp({
  //       departmentX,
  //       divisionX,
  //       sectionUnit,
  //       sectionUnitX,
  //       assignedDept,
  //       dept,
  //       divisionN,
  //       disabledSection,
  //       role,
  //       setOpenModal,
  //       status,
  //       isMaintenance,
  //       captcha,
  //       showButton,
  //     });
  //     setshowButton(false)
  //   }else {
  //     notification["error"]({
  //       message: "Error",
  //       description: "You must answer captcha to register.",
  //     });
  //   }
  // }

  const handleCaptcha = () => {
    try{
      register({
        name: fname,
        mname,
        lname,
        username,
        password,
        dept,
        district: "",
        gender,
        contact,
        birthday,
      });
      setshowButton(false)
    }catch(err){
      console.log(err)
    }
  }

  const data = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];

  // const depts = [
  //   "Office of the City Mayor",
  //   "Office of the City Councilors",
  //   "Office of the Vice Mayor",
  //   "Office of the City Administrator",
  //   "Office of the Secretary to the Mayor",
  //   "Office of the Secretary to the Sangguniang Panlungsod",
  //   "City Treasurer’s Office",
  //   "Barangay and Community Relations Department",
  //   "Board of Assessment Appeals",
  //   "Business Affairs Coordinating Office",
  //   "Business Permits and Licensing Department",
  //   "City Accounting Department",
  //   "City Architect Department",
  //   "City Assessor’s Office",
  //   "City Budget Department",
  //   "City Civil Registry Department",
  //   "City General Services Department",
  //   "Quezon City Health Department",
  //   "City Legal Department",
  //   "City Planning and Development Department",
  //   "City Veterinary Department",
  //   "Department of Public Order and Safety",
  //   "Department of the Building Official",
  //   "Quezon City Engineering Department",
  //   "Climate Change and Environmental Sustainability Department",
  //   "Housing Community Development and Resettlement Department",
  //   "Human Resource Management Department",
  //   "Liquor Licensing and Regulatory Board",
  //   "Market Development and Administration Department",
  //   "Novaliches District Center",
  //   "Novaliches District Hospital",
  //   "Office for the Senior Citizen’s Affairs",
  //   "Parks Development and Administration Department",
  //   "People’s Law Enforcement Board",
  //   "Persons with Disability Affairs Office",
  //   "Procurement Department",
  //   "Public Affairs and Information Services Department",
  //   "Public Employment Service Office",
  //   "Quezon City Center for Children with Disabilities",
  //   "Quezon City Disaster and Risk Reduction Management Office",
  //   "Quezon City Information Technology Development Department",
  //   "Quezon City Tourism Department",
  //   "Quezon City Fire District",
  //   "Quezon City General Hospital",
  //   "Quezon City Police District",
  //   "Quezon City Protection Center for Victims/Survivors of Gender Based Violence And Abuse",
  //   "Quezon City Public Library",
  //   "Quezon City University",
  //   "Radio Communications Service",
  //   "Rosario Maclang Bautista General Hospital",
  //   "Quezon City Youth Development Office",
  //   "Small Business and Cooperatives Development and Promotions Office",
  //   "Social Services Development Department",
  //   "Schools Division Office Quezon City",
  //   "Task Force for Transport and Traffic Management (TF-TTM)",
  //   "Department of Sanitation and Cleanup Works of Quezon City",
  //   "Youth Development Office",
  //   "Local Economic Investment Promotions Office",
  //   "House Resources Management Department",
  //   "Investment Affairs Office",
  //   "Others",
  // ];
  const depts = [
    "DBO",
    "ZAU",
    "BFP"
  ];

  const handleDatePick = (date, dateString) => {
    try{
      setbirthday(dateString);
    }catch(err){
      console.log(err)
    }
  }
  return (
    <>
      <div className="mainRegister-div">
        <div className="mainHeader-login">
          <div className="loginHead-title">
          </div>
          <div className="loginHead-date">
            {`${day}, ${date}`}
          </div>
        </div>
        <div className="registerCard-div">
          <form
            onSubmit={handleSubmit}
            className="loginCard-left"
            style={{ width: "100%", padding: "30px 0" }}
          >
            <div className="loginTitle">REGISTER</div>
            <div className="reg2-row">
              <span>
                <label className="loginInput-label" htmlFor="fname">
                  Firstname *
                </label>
                <Input
                  disabled={disabled}
                  id="fname"
                  placeholder="Enter your first name"
                  onChange={(e) => setfname(e.target.value)}
                  className="loginInput"
                  required
                />
              </span>
              <span>
                <label className="loginInput-label" htmlFor="mname">
                  Middlename
                </label>
                <Input
                  disabled={disabled}
                  id="mname"
                  placeholder="Enter your middle name"
                  onChange={(e) => setmname(e.target.value)}
                  className="loginInput"
                  // type="email"
                />
              </span>
            </div>
            <div className="reg2-row">
              <span>
                <label className="loginInput-label" htmlFor="lname">
                  Lastname *
                </label>
                <Input
                  disabled={disabled}
                  id="lname"
                  placeholder="Enter your last name"
                  onChange={(e) => setlname(e.target.value)}
                  className="loginInput"
                  // type="email"
                  required
                />
              </span>
              <span>
                <label className="loginInput-label" htmlFor="uname">
                  Username (Email Address) *
                </label>
                <Input
                  disabled={disabled}
                  // prefix={}
                  onChange={(e) =>
                    setusername(e.target.value)
                  }
                  id="uname"
                  type="email"
                  placeholder="Enter your username"
                  className="loginInput"
                  required
                />
              </span>
            </div>
            <div className="reg2-row">
              <span>
                <label className="loginInput-label" htmlFor="bday">
                  Birth Date *
                </label>
                <DatePicker style={{width: "100%"}} id="bday" onChange={handleDatePick} />
              </span>
              <span>
                <label className="loginInput-label" htmlFor="gender">
                  Gender *
                </label>
                <Select
                  placeholder="Select gender"
                  onChange={(e) => setgender(e)}
                  id="gender"
                  className="loginInput"
                  required
                >
                  <Option disabled value=""></Option>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </span>
            </div>
            <div className="reg2-row">
              <span>
                <label className="loginInput-label" htmlFor="dept">
                  Department *
                </label>
                <Select
                  required
                  showSearch
                  placeholder="Select your department"
                  onChange={(e) => setDept(e)}
                  id="dept"
                  className="loginInput"
                  required
                >
                  {depts.sort().map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </span>
              <span>
                <label className="loginInput-label" htmlFor="con">
                  Contact *
                </label>
                <Input
                  disabled={disabled}
                  // prefix={}
                  onChange={(e) => setcontact(e.target.value)}
                  id="con"
                  addonBefore="+63"
                  maxLength={10}
                  minLength={10}
                  // type="tel"
                  placeholder="Enter your contact number"
                  className="loginInput"
                  required
                />
              </span>
            </div>
            
            <div className="reg2-row">
              {/*<span>*/}
                {/*<label className="loginInput-label" htmlFor="div">
                  Division
                </label>*/}

                {/*<Select
                  showSearch
                  value={divisionN}
                  onChange={e => changeDivision(e)}
                  className="loginInput"
                  placeholder="Select Division"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  required
                >
                  {divisionX.map((e, key) => {
                    return (
                      <Option key={key} value={e.divisionName}>
                        {e.divisionName}
                      </Option>
                    );
                  })}
                </Select>*/}
              {/*</span>*/}
              {/*<span>*/}
                {/*<label className="loginInput-label" htmlFor="div">
                  Section/Unit
                </label>
                <Select
                  showSearch
                  value={sectionUnitX}
                  onChange={(e) => setSectionUnitX(e)}
                  className="loginInput"
                  placeholder="Select Section/Unit"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  required
                  disabled={disabledSection}
                >
                  {sectionUnit.map((e, key) => {
                    return (
                      <Option key={key} value={e}>
                        {e}
                      </Option>
                    );
                  })}
                </Select>*/}
              {/*</span>*/}
              {/*<span>
                <label className="loginInput-label" htmlFor="district">
                  District *
                </label>
                <Input
                  disabled={disabled}
                  id="district"
                  placeholder="Enter your district"
                  onChange={(e) => setdistrict(e.target.value)}
                  className="loginInput"
                  // type="email"
                  required
                />
              </span>*/}
              {/*<span>
                <label className="loginInput-label" htmlFor="role">
                  Role *
                </label>
                <Select
                  id="role"
                  placeholder="Select role"
                  onChange={(e) => setrole(e)}
                  className="loginInput"
                  required
                >
                  <Option disabled value={null}></Option>
                  <Option value="Evaluator">Evaluator</Option>
                  <Option value="Encoder">Encoder</Option>
                </Select>
              </span>*/}
            </div>

            <div className="reg2-row">
              <span>
                {/*<label className="loginInput-label" htmlFor="pos">
                  Position *
                </label>
                <Input
                  disabled={disabled}
                  // prefix={}
                  onChange={(e) =>setbirthday(e.target.value)}
                  id="pos"
                  placeholder="Enter your position"
                  className="loginInput"
                  required
                />*/}
              </span>
              
            </div>

            <div className="reg2-row">
              <span>
                <label className="loginInput-label" htmlFor="pwd">
                  Password *
                </label>
                <Input.Password
                  disabled={disabled}
                  // prefix={}
                  onChange={(e) =>
                    setpassword(e.target.value)
                  }
                  minLength="6"
                  id="pwd"
                  type="password"
                  placeholder="Enter your password"
                  className="loginInput"
                  required
                />
              </span>
              <span>
                <label className="loginInput-label" htmlFor="cpwd">
                  Confirm Password *
                </label>
                <Input.Password
                  disabled={disabled}
                  // prefix={}
                  onChange={(e) =>
                    setcpassword(e.target.value)
                  }
                  minLength="6"
                  id="cpwd"
                  type="password"
                  placeholder="Confirm your password"
                  className="loginInput"
                  required
                />
              </span>
            </div>

            <span>
              {/*<div>
                <Checkbox required>I give my consent to the </Checkbox>
                <span
                  className="dataPrivacy"
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/dataPrivacy`,
                      "_blank"
                    )
                  }
                >
                  Data Privacy Agreement
                </span>
              </div>*/}
              <button
                disabled={disabled}
                className="btns btnLogin"
              >
                Create Account
              </button>
            </span>

            <div className="regFoot">
              <span>Already have an account?</span>{" "}
              <span onClick={() => (window.location.pathname = "/login")}>
                Login here
              </span>
            </div>

          </form>
        </div>
      </div>
      <Modal
        title={
          <Alert
            message="Kindly check your details before creating an account"
            type="info"
            showIcon
            className={"mx-3"}
            style={{ textAlign: "center" }}
          />
        }
        keyboard={false}
        visible={setOpenModal}
        maskClosable={true}
        closable={false}
        onCancel={() => setsetOpenModal(false)}
        footer={[
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <Button
                danger
                onClick={() => setsetOpenModal(false)}
              >
                Cancel
              </Button>
            </span>
            <span>
              {/*<Button disabled={showButton == false? true : captcha? false : true} onClick={handleCaptcha}>
                OK
              </Button>*/}
              <Button disabled={showButton == false? true : false} onClick={handleCaptcha}>
                OK
              </Button>
            </span>
          </div>,
        ]}
      >
        <Card>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={`${fname} ${mname ? mname : ""} ${lname}`}
                description="FULL NAME"
              />
            </List.Item>
          </List>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={contact}
                description="Contact Number"
              />
            </List.Item>
          </List>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={gender == "female"? "Female" : gender == "male"? "Male" : "Prefer not to say"}
                description="Gender"
              />
            </List.Item>
          </List>

          {/*<>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={dept}
                  description="Home Department"
                />
              </List.Item>
            </List>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={assignedDept}
                  description="Assigned Department"
                />
              </List.Item>
            </List>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={divisionN}
                  description="Division"
                />
              </List.Item>
            </List>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={sectionUnitX}
                  description="Section/Unit"
                />
              </List.Item>
            </List>
          </>*/}
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={dept}
                description="Department"
              />
            </List.Item>
          </List>
          {/*<List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={role}
                description="Role"
              />
            </List.Item>
          </List>*/}
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={birthday}
                description="Birthday"
              />
            </List.Item>
          </List>
          {/*<Row className="contact-us-row">
            <Col className="contact-us-col" span={24} >
              {
                setOpenModal == true? (
                  <>
                    <RecaptchaRegister showButton={e => setshowButton(e)} setCaptcha={(e) => captcha(e)} />
                  </>
                ) : (null)
              }
            </Col>
          </Row>*/}
        </Card>
      </Modal>
    </>
    );
}
