// import logo from "./logo.svg";
import React, { useEffect } from "react"; //  { useEffect }
import "./App.css";
import "antd/dist/antd.css";

import Home from "./components/home/home";
import Login from "./components/login/login";
import ChangePassword from "./components/login/changePassword";
import Register from "./components/register/register.js";
import DataPrivacy from "./components/register/dataPrivacy.js";
import ExpireToken from "./components/ExpiredToken/TokenExpired.js";
import PageNotFound from "./components/PageNotFound";
import ProtectedRoute from "./components/AppLayout/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { notification } from "antd";
import { createUser } from "./api/mutations";
import { userbyUsername } from "./api/queries";
import Verify from "./components/verify/verify";
import axios from "axios";
Amplify.configure(awsconfig);

// >>New - Configuring Auth Module
Auth.configure(awsconfig);

function App() {
  const [regState, setRegState] = React.useState("");

  async function signUp(e) {
    const username = e.username;
    const password = e.password;
    const birthdate = e.date;
    const name = e.name;
    const mname = e.mname;
    const lname = e.lname;
    const district = e.district;
    const contact = `+63${e.contact}`;
    const role = e.role;
    const date = new Date();
    const gender = e.gender;
    const status = "Approved";
    const department = e.department? e.department : "DBO";

    // console.log(username);

    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          name,
          middle_name: mname ? mname : "",
          family_name: lname,
          birthdate,
          email: username,
          phone_number: contact,
          gender,
          "custom:role": role,
          "custom:date": date,
          "custom:status": status,
          "custom:district": district ? district : "",
          "custom:department": department? department : "DBO"
        },
      });

      API.graphql(
        graphqlOperation(createUser, {
          input: {
            name,
            mname,
            lname,
            contact,
            bdate: birthdate,
            username,
            status,
            role,
            date,
            gender,
            district,
            department
          },
        })
      )
        .then((res) => {
          notification["success"]({
            message: "Success",
            description:
              "Please confirm your account using the code sent to your email. Thank you",
          });

          setRegState("");
          setRegState("success");
          console.log(regState); //
        })
        .catch((err) => {
          notification["error"]({
            message: "Error",
            description: err.message,
          });
        });
    } catch (error) {
      // setTimeout(() => {
      setRegState("");
      setRegState(error.message);
      // console.log(error);
      // message.error(error.message);
      notification["error"]({
        message: "Error",
        description: error.message,
      });
      // }, 3000);
    }
  }

  async function signIn(e) {
    localStorage.clear();
    const { username, password } = e;
    // var roles = "";
    try {
      const user = await Auth.signIn(username, password);
      if(user){
        const userToken = JSON.stringify(user.signInUserSession.idToken);
        const role = user.attributes["custom:role"];
        const email = user.attributes.email;

        const checkIfListed = await API.graphql(graphqlOperation(userbyUsername, {username: email}));
        if(checkIfListed.data.UserbyUsername.items.length){
          axios
            .post(
              "https://www.sparksoft-demo.com/qcdbo-cfeibgy-be/userLogin", 
              {
              jwt: userToken,
            })
            .then((res) => {
              console.log(user["attributes"]["custom:role"].includes("|"));
              if(user) {
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", user.signInUserSession.accessToken.jwtToken);
                localStorage.setItem("link", res.data.redirect);
              }
              setRegState("");
              setRegState("success");
              notification["success"]({
                message: "Success",
                description: `Account verified. Welcome ${user.attributes.name}`,
              });

              setTimeout(() => {
                // role.toUpperCase() === "ADMIN"
                //   ? (window.location.pathname = "/")
                //   : (window.location = res.data.redirect);
                const userRole = user["attributes"]["custom:role"].toUpperCase();

                if (userRole === "ADMIN" || userRole === "SUPERADMIN" || userRole.includes("|")) {
                  window.location.pathname = "/";
                } else {
                  window.location = res.data.redirect;
                }
              }, 2000);
            })
            .catch(err => console.log(err))
        }else {
          const attr = user.attributes
          const {
            name,
            birthdate,
            middle_name,
            family_name,
            phone_number,
            email,
            gender

          } = attr;

          const listUser = await API.graphql(
            graphqlOperation(createUser, {
              input: {
                name,
                mname: middle_name,
                lname: family_name,
                contact: phone_number,
                bdate: birthdate,
                username: email,
                status: attr["custom:status"],
                role: "UNASSIGNED",
                date: attr["custom:date"],
                gender,
                district: "",
                department: attr["custom:department"]
              },
            })
          );

          if(listUser.data.createUser){
            axios
              .post(
                "https://www.sparksoft-demo.com/qcdbo-cfeibgy-be/userLogin", 
              {
                jwt: userToken,
              })
              .then((res) => {
                if(user) {
                  localStorage.setItem("user", JSON.stringify(user));
                  localStorage.setItem("token", user.signInUserSession.accessToken.jwtToken);
                  localStorage.setItem("link", res.data.redirect);
                }
                setRegState("");
                setRegState("success");
                notification["success"]({
                  message: "Success",
                  description: `Account verified. Welcome ${user.attributes.name}`,
                });

                setTimeout(() => {
                  role.toUpperCase() === "ADMIN"
                    ? (window.location.pathname = "/")
                    : (window.location = res.data.redirect);
                }, 2000);
              })
              .catch(err => console.log(err))
          }else {
            notification["error"]({
              message: "Error",
              description: `Login error! Please contact System Admin.`,
            });
          }
        }
          
      }

    } catch (error) {
      setRegState("");
      setRegState(error.message);
    }
  }

  async function confirmNow(e) {
    const username = e.confirmEmail;
    const code = e.code;
    try {
      await Auth.confirmSignUp(username, code);
      notification["success"]({
        message: "Success",
        description:
          "We have been verified your account. Please login to continue.",
      });
      setRegState("");
      setRegState("successConfirm");
    } catch (error) {
      setRegState("");
      setRegState(error.message);
      notification["error"]({
        message: "Error",
        description: error.message,
      });
    }
  }

  async function confirmSignUp(e) {
    try {
      await Auth.confirmSignUp(e.confirmEmail, e.code);
      await notification["success"]({
        message: "Success",
        description: `Account verified. You can now login`,
      });
      setTimeout(() => {
        window.location.pathname = "/login";
      }, 2000);
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: error.message,
      });
    }
  }

 async function signOut() {
  try {
    await Auth.signOut({ global: true });
    localStorage.clear();
    window.location.reload();
  } catch (error) {
    if (error.code !== 'NotAuthorizedException') {
      console.log("error signing out: ", error);
      return;
    }
    // If the error is a NotAuthorizedException, continue with the sign out process
  }


}

  async function resend(e) {
    const username = e;
    try {
      await Auth.resendSignUp(username);
      notification["success"]({
        message: "Success",
        description: `Code re-sent to your email. Please check.`,
      });
    } catch (err) {
      notification["error"]({
        message: "Error",
        description: err.message,
      });
    }
  }

  async function openFb() {
    console.log("fb");
    Auth.federatedSignIn({ provider: "Facebook" });
  }

  async function openGoogle() {
    console.log("google mail");
    Auth.federatedSignIn({ provider: "Google" });
  }

  async function register(e) {
    const {
      name,
      mname,
      lname,
      username,
      password,
      gender,
      contact,
      birthday,
      district,
      dept,
    } = e;

    const date = new Date();
    const status = "Approved";

    try {
      const { user } = await Auth.signUp({
        username, 
        password,
        attributes: {
          name,
          middle_name: mname ? mname : "",
          family_name: lname,
          birthdate: birthday,
          email: username,
          phone_number: `+63${contact}`,
          gender,
          "custom:role": "UNASSIGNED",
          "custom:date": date,
          "custom:status": status,
          "custom:district": district ? district : "",
          "custom:department": dept,
        },
      });

      if (user) {
          localStorage.setItem("email", username);
          notification["success"]({
            message: "Success",
            description: "Verification code has been sent to your email.",
            // duration: 5,
          });

          setTimeout(() => {
            window.location.pathname = "/verify";
          }, 3000);
        }
    } catch (error) {
      setRegState("");
      setRegState(error.message);

      console.log(error)
      
      notification["error"]({
        message: "Error",
        description: error.message,
      });
    }
  }

  return (
    <AuthProvider>
      <Router>
        <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={(props) => (
            <Home
              {...props}
              logout={signOut}
              signUp={signUp}
              regState={regState}
              resend={resend}
              verify={confirmNow}
            />
          )}
        />

          <Route
            path="/login"
            render={(props) => (
              <Login
                {...props}
                signIn={signIn}
                regState={regState}
                fb={openFb}
                google={openGoogle}
              />
            )}
          />

          <Route
            path="/expireToken"
            render={(props) => (
              <ExpireToken />
            )}
          />

          <Route
            path="/register"
            render={(props) => (
              <Register
                register={register}
              />
            )}
          />

          <Route
            path="/changePassword"
            render={(props) => (
              <ChangePassword
                {...props}
                signIn={signIn}
                regState={regState}
              />
            )}
          />

          <Route
            path="/verify"
            render={(props) => (
              <Verify {...props} verify={confirmSignUp} resend={resend} />
            )}
          />

          <Route
            path="/dataPrivacy"
            render={(props) => (
              <DataPrivacy />
            )}
          />

          <Route 
             path="*"
             render={() => <PageNotFound />}   
          />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
