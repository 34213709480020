import { Button, Input, Modal, notification, Radio } from "antd";
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import AdditionalRolesDepts from "./additionalRolesDepts";

import { API, graphqlOperation } from "aws-amplify";
// import moment from "moment";
import React from "react";
import { updateUser, createUser } from "../../api/mutations";

let AWS = require("aws-sdk");
let cognitoISP = new AWS.CognitoIdentityServiceProvider({
  region: "ap-southeast-1",
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default class EditUser extends React.Component {
  constructor() {
    super();
    this.state = {
      roles: [], // Added roles and depts state -RYE
      depts: [],
      districts: [],
    };
  }

  componentDidMount() {
    this.setState({
      name: this.props.data.name,
      mname: this.props.data.mname,
      lname: this.props.data.lname,
      dept: this.props.data.department,
    });

    this.fillFields();
  }

  fillFields = async () => {
    const d = this.props.data;
    this.setState({ district: d.district, role: d.role });
  };

  // Added handleRolesDeptsChange method -RYE
  handleRolesDeptsChange = (newRoles, newDepts, newDistricts) => {
    this.setState({
      roles: newRoles,
      depts: newDepts,
      districts: newDistricts,
    });
  };

  handleSubmit = () => {
    const e = this.state;
    const d = this.props.data;

    // Convert roles and depts to arrays
    const rolesArray = e.roles.split("|");
    const deptsArray = e.depts.split("|");

    // Check if roles field is not null or empty
    if (
      !rolesArray ||
      rolesArray.length === 0 ||
      rolesArray.some((role) => role === "")
    ) {
      notification["error"]({
        message: "Error",
        description: "Roles field cannot be empty.",
      });
      return;
    }

    // Check if departments field is not null or empty
    if (
      !deptsArray ||
      deptsArray.length === 0 ||
      deptsArray.some((dept) => dept === "")
    ) {
      notification["error"]({
        message: "Error",
        description: "Departments field cannot be empty.",
      });
      return;
    }

    // Check for duplicate departments rye050224
    const uniqueDepts = new Set(deptsArray.map(dept => dept.toLowerCase())); // Convert all departments to lowercase rye050724
    if (uniqueDepts.size !== deptsArray.length) {
      notification["error"]({
        message: "Error",
        description: "Duplicate departments detected.",
      });
      return;
    }
    
    
    if(!d.hasOwnProperty('saveMe')) {
      if (rolesArray.includes("EVALUATOR") || rolesArray.includes("ENCODER")) {
        if (!e.district) {
          notification["error"]({
            message: "Error",
            description: "District is required.",
          });
          return;
        } else {
          this.updateUser(d, e);
        }
      } else {
        this.updateUser(d, e);
      }
    } else {
        this.handleExistingCognitoUserNotInDynamoDB(d, e);
    }
 
  };

  handlePasswordModal = () => {
    this.setState({
      open: false,
      openPwModal: true,
    });
  };

  handleSubmitPWord = () => {
    if (this.state.changePWord === this.state.confirmPWord) {
      let AWSchangePw = new Promise((resolve, reject) => {
        let params = {
          Password: this.state.changePWord,
          Permanent: true,
          UserPoolId: "ap-southeast-1_8bRtLOuCc",
          Username: this.props.data.username,
        };

        cognitoISP.adminSetUserPassword(params, (err, data) =>
          err ? reject(err) : resolve("Success")
        );
      });

      AWSchangePw.then((res) => {
        notification["success"]({
          message: "Success",
          description: "Password is changed.",
        });

        console.log(res);

        setTimeout(() => {
          this.setState({
            openPwModal: false,
            open: true,
          });
        }, 2500);
      }).catch((error) => {
        notification["error"]({
          message: "Error",
          description: error.stack,
        });

        console.log(error);
      });
    } else {
      notification["error"]({
        message: "Error",
        description: "Password must match.",
      });
    }
  };

async handleExistingCognitoUserNotInDynamoDB(d, e) {
  try {
    const resultAfterUpdate = await API.graphql(
      graphqlOperation(createUser, {
        input: {
          name: d.name || '',
          mname: d.mname || '',
          lname: d.lname || '',
          contact: d.contact || '',
          bdate: d.bdate || '',
          username: d.username || '',
          status: d.status || '',
          role: d.role || '',
          date: d.date || '',
          gender: d.gender || '',
          district: d.district || '',
          department: d.department || '',
        }
      })
    );

    this.updateUser(resultAfterUpdate.data.createUser, e)   
    this.props.refresh();
    this.setState({ open: false });
  } catch (error) {
    console.error(error);
    notification["error"]({
      message: "Error",
      description: "Something went wrong.",
    });
  }
}

  updateUser = async (d, e) => {
    const { name, mname, lname, depts, roles, districts } = this.state;

    const rolesArray = e.roles.split("|");
    const deptsArray = e.depts.split("|");
    const districtArray = e.districts.split("|");

    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: d.id,
            role: roles,
            district: districts,
            name,
            mname,
            lname,
            department: depts,
          },
        })
      );

      const params = {
        UserAttributes: [
          { Name: "custom:role", Value: rolesArray.join("|") },
          { Name: "custom:department", Value: deptsArray.join("|") },
          { Name: "custom:district", Value: districtArray.join("|") },
          { Name: "given_name", Value: name },
          { Name: "name", Value: name },
          { Name: "middle_name", Value: mname },
          { Name: "family_name", Value: lname },
        ],
        UserPoolId: "ap-southeast-1_8bRtLOuCc",
        Username: d.username,
      };

      await new Promise((resolve, reject) => {
        cognitoISP.adminUpdateUserAttributes(params, (err, data) =>
          err ? reject(err) : resolve(data)
        );
      });

      notification["success"]({
        message: "Success",
        description: "User has been edited.",
      });

      this.props.refresh();
      this.setState({ open: false });
    } catch (error) {
      console.error("Error updating user");
    }
  };

  render() {
    const options = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ];

    const optionsRole = [
      { label: "DBO Admin", value: "DBO Admin" },
      { label: "Department Chief", value: "Department Chief" },
      { label: "Department Admin", value: "Department Admin" },
      { label: "Evaluator", value: "Evaluator" },
      { label: "Encoder", value: "Encoder" },
    ];
    const d = this.props.data;
    // console.log(d);

    // const { formComponent } = AdditionalRolesDepts({
    //   existingRole:'this.state.role' ,
    //   existingDept:'this.state.dept' ,
    //   onRolesDeptsChange: 'This is a placeholder'});

    return (
      <>
        <Button
          onClick={() => this.setState({ open: true })}
          type="primary"
          size="small"
          style={{width:"56px",borderRadius:"20px"}}
        >
         <EditOutlined />
        </Button>
        <Modal
          style={{ top: 20 }}
          title="Edit user"
          onCancel={() => {
            this.setState({ open: false });
            this.fillFields();
          }}
          visible={this.state.open}
          width={700}
          footer={[
            <Button
              type="danger"
              onClick={() => {
                this.setState({ open: false });
                this.fillFields();
              }}
              ghost
            >
              Cancel
            </Button>,
            <Button type="primary" onClick={this.handleSubmit}>
              Edit
            </Button>,
            // <Button onClick={() => [console.log(this.state.roles), console.log(this.state.depts)]}>Log Roles and Depts</Button>
          ]}
        >
          {d ? (
            <form onSubmit={this.handleSubmit} className="editUserMain-div">
              {/* <div className="row1"></div> */}

              <div className="row3">
                <Input
                  value={d.username}
                  type="email"
                  name="email"
                  placeholder="Enter your email*"
                  disabled
                />

                <div></div>
                <Button type="primary" onClick={this.handlePasswordModal}>
                  Change Password
                </Button>
              </div>
              <div className="row1">
                <Radio.Group
                  options={options}
                  value={d.gender}
                  optionType="button"
                  buttonStyle="solid"
                  disabled
                />
              </div>

              <div className="row3">
                <Input
                  value={this.state.name}
                  required
                  placeholder="Enter your firstname*"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <Input
                  value={this.state.mname}
                  required
                  placeholder="Enter your middlename"
                  onChange={(e) => this.setState({ mname: e.target.value })}
                />
                <Input
                  value={this.state.lname}
                  required
                  placeholder="Enter your lastname*"
                  onChange={(e) => this.setState({ lname: e.target.value })}
                />
              </div>

              <div className="row2">
                {/* <Input
                  onChange={(e) => this.setState({ district: e.target.value })}
                  value={this.state.district}
                  defaultValue={d.district}
                  placeholder={`Enter your district${
                    this.state.role === "Encoder" ||
                    this.state.role === "Evaluator"
                      ? "*"
                      : ""
                  }`}
                  required={
                    this.state.role === "Encoder" ||
                    this.state.role === "Evaluator"
                      ? true
                      : false
                  }
                /> */}
                <Input
                  value={d.contact}
                  placeholder=" Enter your contact*"
                  disabled
                />

                <Input
                  value={d.bdate}
                  placeholder="Enter your birthday*"
                  disabled
                />
              </div>
              <h5>Roles & Departments</h5>

              <div className="row2">
                <AdditionalRolesDepts
                  existingRole={this.state.role}
                  existingDept={this.state.dept}
                  existingDistrict={this.state.district} // Added existingDistrict prop -(rye 04/25/24)
                  onRolesDeptsChange={this.handleRolesDeptsChange}
                />
              </div>
            </form>
          ) : null}
        </Modal>
        <Modal
          style={{ top: 20 }}
          title="Change Password"
          onCancel={() => {
            this.setState({
              openPwModal: false,
              open: true,
            });
            this.fillFields();
          }}
          visible={this.state.openPwModal}
          width={500}
          footer={[
            <Button
              type="danger"
              onClick={() => {
                this.setState({
                  openPwModal: false,
                  open: true,
                });
                this.fillFields();
              }}
              ghost
            >
              Cancel
            </Button>,
            <Button type="primary" onClick={this.handleSubmitPWord}>
              Submit
            </Button>,
          ]}
        >
          <p>{`Username: ${d.username}`}</p>
          <p>{`Name: ${this.state.name} ${this.state.mname} ${this.state.lname}`}</p>
          <form onSubmit={this.handleSubmitPWord} className="editUserMain-div">
            <Input.Password
              autoFocus
              required
              value={this.state.changePWord}
              onChange={(e) => this.setState({ changePWord: e.target.value })}
              placeholder="Enter password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <Input.Password
              autoFocus
              required
              value={this.state.confirmPWord}
              onChange={(e) => this.setState({ confirmPWord: e.target.value })}
              placeholder="Confirm password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </form>
        </Modal>
      </>
    );
  }
}
