import "./register.css";
import {
  Result,
  Button,
  DatePicker,
  Input,
  notification,
  Steps,
  Breadcrumb,
  Radio,
} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";

import React from "react";
import AdditionalRolesDepts from "./additionalRolesDepts.js";

import moment from "moment";
// import TextLoop from "react-text-loop";

const { Step } = Steps;

export default class Register extends React.Component {
  constructor() {
    super();
    this.state = { current: 0, gender: "Male", role: "" };
  }

  onRolesDeptsChange = (roles, depts, district) => {
    // rye testing roles and depts
    console.log(roles, depts, district);
    this.setState({ role: roles, department: depts, district: district });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      console.log(this.props);
      if (this.props.regState === "success") {
        this.setState({
          current: this.state.current + 1,
          loading: false,
          name: "",
          username: "",
          date: "",
          date2: "",
          password: "",
          district: "",
          confirmEmail: this.state.username,
        });
      } else if (this.props.regState === "successConfirm") {
        this.setState({ confirmEmail: "", code: "", current: 2, open: false });
      } else if (this.props.regState === "User already exists") {
        this.setState({
          current: 0,
          loading: false,
          confirmEmail: this.state.username,
        });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  disabledDate(current) {
    // console.log(current);
    // Can not select days before today and today
    return current && current > moment().subtract(4383, "days");
  }

  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
  
    if (!this.state.role.length) {
      notification["error"]({
        message: "Error",
        description: "Role can't be empty.",
      });
      this.setState({ loading: false }); // rye attempt to keep the submit button enabled -goods!
      return;
    }
  
    if (!this.state.department) {
      notification["error"]({
        message: "Error",
        description: "Department can't be empty.",
      });
      this.setState({ loading: false }); // rye attempt to keep the submit button enabled -goods!
      return;
    }
  
    if (!this.state.district) {
      notification["error"]({
        message: "Error",
        description: "District can't be empty. Place '--' if not applicable.",
      });
      this.setState({ loading: false }); // rye attempt to keep the submit button enabled -goods!
      return;
    }
  
    this.props.signUp(this.state);
  };

  handleChange = (e) => {
    this.setState({ current: e });
  };

  resendCode = async () => {
    if (this.state.confirmEmail) {
      this.props.resend(this.state.confirmEmail);
    } else {
      notification["error"]({
        message: "Error",
        description: "Please enter your email first.",
      });
    }
  };

  handleVerify = async (e) => {
    e.preventDefault();
    this.props.confirm(this.state);
    this.setState({ loading: true });
  };

  render() {
    const options = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ];

    const optionsRole = [
      { label: "DBO Admin", value: "DBO Admin" },
      { label: "Department Chief", value: "Department Chief" },
      { label: "Department Admin", value: "Department Admin" },
      { label: "Evaluator", value: "Evaluator" },
      { label: "Encoder", value: "Encoder" },
    ];

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Register user</Breadcrumb.Item>
        </Breadcrumb>

        <div className="mainReg-div">
          <div className="regDiv-inner">
            <Steps
              current={this.state.current}
              // size="small"
              onChange={this.handleChange}
            >
              <Step
                // description="Step 1: Registration of account"
                status={this.state.current === 0 ? "process" : "finish"}
                title="Register a user"
                icon={
                  this.state.current > 0 ? (
                    <CheckCircleOutlined />
                  ) : (
                    <LoadingOutlined />
                  )
                }
              />
              <Step
                // description="Step 2: Verification of account"
                status={
                  this.state.current === 1
                    ? "process"
                    : this.state.current < 1
                    ? "wait"
                    : "finish"
                }
                //   status="process"
                title="Verify their account"
                icon={
                  this.state.current === 1 ? (
                    <LoadingOutlined />
                  ) : this.state.current < 1 ? (
                    <ClockCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
              />
              <Step
                disabled
                //   loading
                // description="Step 2: Verification of account"
                status={
                  this.state.current === 2
                    ? "process"
                    : this.state.current < 2
                    ? "wait"
                    : "finish"
                }
                //   status="process"
                title="Finish"
                icon={
                  this.state.current === 2 ? (
                    <CheckCircleOutlined />
                  ) : this.state.current < 2 ? (
                    <ClockCircleOutlined />
                  ) : null
                }
              />
            </Steps>
            {this.state.current === 0 ? (
              <form onSubmit={this.handleSubmit} className="registerMain-div">
                <div className="regWarning">
                  *Fill out all the information below
                </div>

                {/* <div className="row3">
                  <Input 
                    autoFocus
                    size="large"
                    required
                    value={this.state.role}
                    onChange={(e) => this.setState({role: e.target.value}) }
                    placeholder="Enter role here" 
                    prefix={<UserOutlined />} 
                  />
                </div> */}

                <div className="row1">
                  <Radio.Group
                    options={options}
                    onChange={(e) => this.setState({ gender: e.target.value })}
                    value={this.state.gender}
                    optionType="button"
                    buttonStyle="solid"
                    required
                  />
                </div>
                <div className="row3">
                  <Input
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name}
                    placeholder="Enter your firstname*"
                    size="large"
                    required
                    autoFocus
                  />
                  <Input
                    onChange={(e) => this.setState({ mname: e.target.value })}
                    value={this.state.mname}
                    placeholder="Enter your middlename*"
                    size="large"
                    // required
                    // autoFocus
                  />
                  <Input
                    onChange={(e) => this.setState({ lname: e.target.value })}
                    value={this.state.lname}
                    placeholder="Enter your lastname*"
                    size="large"
                    // required
                    // autoFocus
                  />
                </div>

                <div className="row2">
                  {/* <Input
                    onChange={(e) =>
                      this.setState({ district: e.target.value })
                    }
                    // type="number"
                    value={this.state.district}
                    size="large"
                    placeholder={`Enter your district${
                      this.state.role === "Encoder" ||
                      this.state.role === "Evaluator"
                        ? "*"
                        : ""
                    }`}
                    required={
                      this.state.role === "Encoder" ||
                      this.state.role === "Evaluator"
                        ? true
                        : false
                    }
                  /> */}

                  {/* <Input
                    onChange={(e) =>
                      this.setState({ department: e.target.value })
                    }
                    placeholder="Enter your department *"
                    value={this.state.department}
                    size="large"
                    required
                  />  */}

                  <Input
                    prefix="+63"
                    onChange={(e) => this.setState({ contact: e.target.value })}
                    minLength={10}
                    maxLength={10}
                    // type="number"
                    value={this.state.contact}
                    size="large"
                    placeholder=" Enter your contact number*"
                    required
                  />

                  <DatePicker
                    value={this.state.date2}
                    onChange={(e, b) => this.setState({ date: b, date2: e })}
                    defaultPickerValue={moment().subtract(4383, "days")}
                    loadingDate={this.loadingDate}
                    placeholder="Enter your birthday*"
                    size="large"
                    required
                  />
                </div>

                <Input
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  type="email"
                  name="email"
                  placeholder="Enter your email*"
                  size="large"
                  required
                />
                <Input.Password
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type="password"
                  placeholder="Enter your password*"
                  size="large"
                  required
                />

                {/* rye attempt */}
                <div className="row1">
                  <AdditionalRolesDepts
                    onRolesDeptsChange={this.onRolesDeptsChange}
                  />
                </div>

                <button disabled={this.state.loading} className="btn">
                  Register
                </button>
              </form>
            ) : this.state.current === 1 ? (
              <form onSubmit={this.handleVerify} className="registerMain-div">
                <Input
                  onChange={(e) =>
                    this.setState({ confirmEmail: e.target.value })
                  }
                  value={this.state.confirmEmail}
                  placeholder="Enter your email"
                  type="email"
                  required
                />

                <Input
                  value={this.state.code}
                  onChange={(e) => this.setState({ code: e.target.value })}
                  type="number"
                  placeholder="Enter your verification code"
                  required
                />
                <button disabled={this.state.loading} className="btn">
                  Verify
                </button>
                <div>
                  <span className="verBtn" onClick={this.resendCode}>
                    Resend verification code
                  </span>
                </div>
              </form>
            ) : (
              <Result
                status="success"
                title="Successfully registered!"
                subTitle="Please login your account and change your password."
                extra={[
                  <Button
                    onClick={() => this.setState({ current: 0 })}
                    type="primary"
                    key="console"
                  >
                    Register another user
                  </Button>,
                ]}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}