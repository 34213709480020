import React, { useState, useEffect } from "react";
import { Input, Row, Typography, Spin, notification } from "antd";
import { useAuth } from "../../context/AuthContext";
import {
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "./login.css";

const { Title } = Typography;

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated, props.history]);

  useEffect(() => {
    if(props.regState !== "") {
      const letMessage = props.regState;
      switch (props.regState) {
        case "Pending sign-in attempt already in progress":
          setLoading(true);
          notification["info"]({
            message: letMessage,
            duration: 5,
          });
          break;
        case "success":
          // Do nothing if the state is success
          break;
        default:
          setLoading(false);
          notification["error"]({
            message: letMessage,
            duration: 5,
          });
          setUsername("");
          setPassword("");
          break;
      }
    }
  }, [props.regState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedUsername = username.trim();
  
    if (username !== trimmedUsername) {
      return;
    }
  
    setLoading(true);
    setTimeout(() => {
      props.signIn({ username, password });
    }, 3000);
  };


    return (
      <>
        <div className="mainLogin-div">
          <div className="mainLogin-left">
            <form className="leftInner-login" onSubmit={handleSubmit}>
              <div>
                <Title level={2} style={{textAlign:"center"}}>Welcome Back!</Title>
              </div>
              <div>
                <Title level={4} style={{textAlign:"center", color:"#df3535"}}>Please sign in to continue!</Title>
              </div>
              <div>
                <Input
                  prefix={<UserOutlined />}
                  type="email"
                  placeholder=" Your username"
                  name="email"
                  onChange={(e) => setUsername(e.target.value)}
                  size="large"
                  required
                  className="loginInput"
                  value={username}
                />
                <Input.Password
                  prefix={<LockOutlined  />}
                  placeholder=" Your password"
                  onChange={(e) =>setPassword(e.target.value )}
                  size="large"
                  required
                  className="loginInput"
                  style={{ marginTop: -2, marginBottom:"10px"}}
                  value={password}
                />
                <a
                  style={{ float: "right", marginBottom:"35px" }}
                  className="regHere"
                  href="/changePassword"
                >
                  Forgot your password?
                </a>
                
                <button className="btn" disabled={loading}>
                  <Spin spinning={loading} delay={500}>
                      SIGN IN
                  </Spin> 
                </button>
              </div>
              <div>
                <Row justify="center">
                  <span>Create a new account?&nbsp;</span>
                  <span
                    className="regHere"
                    onClick={() => this.props.history.push("/register")}
                  >
                    Register here
                  </span>
                </Row>
                <Row justify="center">
                  <span>Account not yet verified?&nbsp;</span>
                  <span
                    className="regHere"
                    onClick={() => this.props.history.push("/verify")}
                  >
                    Verify here
                  </span>
                </Row>      
              </div>
            </form>
          </div>        
        </div>
      </>
    );
}


export default Login;