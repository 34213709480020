import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Table, Input, Button } from 'antd';
import { UserOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';



export default function AdditionalRolesDepts({ existingRole, existingDept, existingDistrict, onRolesDeptsChange }) {
  const [inputRoles, setInputRoles] = useState([]);
  const [inputDepts, setInputDepts] = useState([]);
  const [inputDistricts, setInputDistricts] = useState([]);

  console.log(existingRole, existingDept, existingDistrict);

  useEffect(() => {
    if (existingRole || existingDept || existingDistrict) {
      // Split the rolesString and deptsString by '|'
     const rolesArray = existingRole ? existingRole.split('|') : ["-"];
     const deptsArray = existingDept ? existingDept.split('|') : ["-"];
     const districtsArray = existingDistrict ? existingDistrict.split('|') : ["-"];

      // Initialize inputRoles and inputDepts state with the split values
      setInputRoles(rolesArray.map(role => ({ role })));
      setInputDepts(deptsArray.map(department => ({ department })));
      setInputDistricts(districtsArray.map(district => ({ district })));
    } else {
      // If no existing roles and departments provided, initialize with one empty field each
      setInputRoles([{ role: "" }]);
      setInputDepts([{ department: "" }]);
      setInputDistricts([{ district: "" }]);
    }
  }, [existingRole, existingDept, existingDistrict]);
  

  
  const handleChangeInput = (index, field, value) => {
    if (field === "role") {
      const newRoles = [...inputRoles];
      if (newRoles[index]) {
        newRoles[index][field] = value;
        setInputRoles(newRoles);
      }
    } else if (field === "department") {
      const newDepts = [...inputDepts];
      if (!newDepts[index]) {
        newDepts[index] = {};
      }
      newDepts[index][field] = value;
      setInputDepts(newDepts);
    } else if (field === "district") {
      const newDistricts = [...inputDistricts];
      if (!newDistricts[index]) {
        newDistricts[index] = {};
      }
      newDistricts[index][field] = value;
      setInputDistricts(newDistricts);
    }
  };

  useEffect(() => {
    // Map over inputRoles and inputDepts to create new arrays of formatted strings + district(rye)
    const formattedRoles = inputRoles.map(roleObj => roleObj.role);
    const formattedDepts = inputDepts.map(deptObj => deptObj.department);
    const formattedDistricts = inputDistricts.map(districtObj => districtObj.district);

    // Use join method to combine the arrays into single strings, with each role or department separated by a "|" + district (rye)
    const rolesString = formattedRoles.join("|");
    const deptsString = formattedDepts.join("|");
    const districtsString = formattedDistricts.join("|");

    // Pass the formatted roles and departments to onRolesDeptsChange + district (rye)
    onRolesDeptsChange(rolesString, deptsString, districtsString);
  }, [inputRoles, inputDepts, inputDistricts, onRolesDeptsChange]);

  const handleAddFields = () => {
    setInputRoles([...inputRoles, { role: "" }]);
    setInputDepts([...inputDepts, { department: "" }]);
    setInputDistricts([...inputDistricts, { district: "" }]);
  };

  const handleRemoveFields = (index) => {
    const roles = [...inputRoles];
    const depts = [...inputDepts];
    const districts = [...inputDistricts];
    if (roles.length > 1) {
      roles.splice(index, 1);
      setInputRoles(roles);
    }
    if (depts.length > 1) {
      depts.splice(index, 1);
      setInputDepts(depts);
    }
    if (districts.length > 1) {
      districts.splice(index, 1);
      setInputDistricts(districts);
    }
  };

  return (
    <Container>
      <form className="row1">
        {inputRoles.map((inputRole, index) => (
          <div
            key={index}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Input
              autoFocus
              required
              placeholder="Enter Role here*"
              prefix={<UserOutlined />}
              value={inputRole.role}
              onChange={(event) =>
                handleChangeInput(index, "role", event.target.value)
              }
              style={{ marginLeft:-25, marginBottom:10, height: 33, width: 300}}
            />

            <ArrowRightIcon style={{ marginTop: "5px" }} />

            <Input
              required
              placeholder="Enter Department here*"
              value={inputDepts[index]?.department}
              onChange={(event) =>
                handleChangeInput(index, "department", event.target.value)
              }
              style={{ height: 33, width: 300, marginBottom:10 }}
            />
            
            <ArrowRightIcon style={{ marginTop: "5px" }} />

            <Input
              required
              placeholder="Enter District here*"
              value={inputDistricts[index]?.district}
              onChange={(event) =>
                handleChangeInput(index, "district", event.target.value)
              }
              style={{ height: 33, width: 300, marginBottom:10 }}
            />

            <IconButton onClick={() => handleRemoveFields(index)} style={{ height:33, width: 33 }}>
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleAddFields()} style={{ height:33, width: 33 }}>
              <AddIcon />
            </IconButton>
          </div>
        ))}
      </form>
    </Container>
  );
}